import { Alert } from 'react-native';
import DocumentPicker from 'react-native-document-picker';
import uuid from 'react-native-uuid';

import { useFileValidation } from '@utils/fileValidationUtils';

import { LocalFile } from '../graphql/generated';

export const selectFiles = async (
  allowMultiSelection?: boolean
): Promise<LocalFile[] | undefined> => {
  let result;
  try {
    result = await DocumentPicker.pickMultiple({
      allowMultiSelection,
      copyTo: 'cachesDirectory',
    });
  } catch (e) {
    if (DocumentPicker.isCancel(e)) {
      return undefined;
    } else {
      throw e;
    }
  }

  // Use the file validation hook
  const { isValidExtension } = useFileValidation();

  if (result) {
    const invalidFiles = await Promise.all(
      result.map(async (item) => {
        const isValid = await isValidExtension(item.type);
        return { item, isValid };
      })
    );

    const invalidItems = invalidFiles.filter((file) => !file.isValid);

    if (invalidItems.length > 0) {
      Alert.alert(
        'Upload failed',
        'The selected file type is not supported for upload. To check allowed list of file types, please visit https://tasktag.com/faq.'
      );
      return undefined;
    }

    return (
      result?.map((item): LocalFile => {
        const uri = (item.fileCopyUri || item.uri).toString();
        const { name, type, size } = item;
        const clientId = uuid.v4().toString();

        return {
          __typename: 'LocalFile',
          id: clientId,
          url: uri,
          name: name || '',
          size,
          contentType: type || '',
          clientId,
          isImage: false,
          cdnBaseUrl: '',
          path: '',
          isPreviewable: false,
          thumbnail: '',
        };
      }) || []
    );
  }
};
