import { useNavigation } from '@react-navigation/native';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { Box } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';

type Props<T> = {
  redirectTo?: string;
  children?: ReactNode;
  params?: T;
  isTryFree?: boolean;
  onPress?: () => void;
};

export const CustomToast = <T,>({
  redirectTo,
  children,
  params,
  isTryFree,
  onPress,
}: Props<T>) => {
  const navigation = useNavigation();
  const { t } = useTranslation('models');
  const toast = useToast();

  return (
    <TouchableOpacity
      style={styles.toastContainer}
      onPress={() => {
        if (onPress) {
          onPress();
        }
        toast.hideAll();
        redirectTo && navigation.navigate(redirectTo, { ...(params || {}) });
      }}>
      <Box style={styles.closeButton}>
        <Icon
          width={15}
          height={10}
          variant='l'
          color='white'
          name='Close'
          onPress={(e) => {
            e.stopPropagation();
            toast?.hideAll();
          }}
        />
      </Box>

      <Icon
        width={48}
        height={36}
        variant='l'
        color='textPrimary'
        name='Winners'
      />
      {children || (
        <Text style={styles.mainText}>{t('customToast.upgradeToCreate')}</Text>
      )}
      {isTryFree && (
        <View style={styles.containerLink}>
          <Text style={styles.link}>{t('customToast.tryFree')}</Text>
        </View>
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  toastContainer: {
    padding: 10,
    backgroundColor: '#303742',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 8,
    position: 'relative',
  },
  mainText: {
    color: '#fff',
  },
  link: {
    color: '#1ba77d',
  },
  containerLink: {
    backgroundColor: '#dcf2ec',
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    padding: 5,
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
});
