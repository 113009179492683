import * as Device from 'expo-device';
import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

import { PopupIcon } from '@components/Web/PopupIcon';
import {
  ProjectMemberRole,
  TaskMemberRole,
  TeamMemberRole,
  useGetTaskQuery,
} from '@graphql/generated';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { useProjectFromQuery } from '@hooks/useProjectFromQuery';
import theme from '@themes/theme';

export const MediaShareModal: React.FC<{
  isGlobalSearch?: boolean;
  fromChatMessage?: boolean;
  fromChatId?: string;
  onDeleteFile?: () => void;
}> = ({ isGlobalSearch = false, fromChatMessage = false, onDeleteFile }) => {
  const { t } = useTranslation('models');
  const { activeIndex, previewDocuments } = usePreviewDocuments();
  const taskDocs = previewDocuments?.[activeIndex].taskDocuments || [];
  const projectDocs = previewDocuments?.[activeIndex].projectDocuments || [];

  let role = '';
  let teamRole = '';
  if (!isGlobalSearch && taskDocs.length > 0) {
    const taskId = taskDocs[0].task?.id;
    const { data: taskData } = useGetTaskQuery({
      variables: {
        id: taskId,
      },
      skip: !taskId,
    });
    const { getTask: task } = taskData || {};
    role = task?.currentUserRole || '';
    teamRole = task?.currentUserProjectTeamRole || '';
  } else if (!isGlobalSearch && projectDocs.length > 0) {
    const projectId = projectDocs[0].project?.id;
    const { project } = useProjectFromQuery({
      projectId,
    });
    role = project?.currentUserRole || '';
    teamRole = project?.currentUserTeamRole || '';
  }

  const onShare = async () => {
    try {
      let url = previewDocuments?.[activeIndex]?.file?.url;
      const image = previewDocuments?.[activeIndex].file;
      const isLocal =
        image?.url.startsWith('file:') || image?.url.startsWith('data:');

      if (isLocal) {
        url = image?.url;
      } else {
        const format = 'f-auto';

        // ImageKit device density options:
        // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#dpr-dpr
        const density = Device.isDevice ? 'dpr-2' : 'dpr-1';

        // ImageKit image quality options:
        // https://docs.imagekit.io/features/image-transformations/resize-crop-and-other-transformations#quality-q
        // Note that a density setting of 2 is recommended to have a lower quality.

        let quality;

        if (Device.isDevice) {
          quality = 'q-60';
        } else {
          quality = 'q-80';
        }

        const transformations = ['/tr:', format, density, quality];
        const transformationList = transformations.join(',');
        url = `${image?.cdnBaseUrl}${transformationList}${image?.path}`;
      }

      navigator.share({
        url: url,
        title: 'TaskTag',
        text: previewDocuments?.[activeIndex]?.name,
      });
    } catch (error) {
      console.log(error);
    }
  };

  let menuList: ComponentProps<typeof PopupIcon>['popupProps']['menuList'];

  if (
    (role !== ProjectMemberRole.Viewer && role !== TaskMemberRole.Viewer) ||
    teamRole === TeamMemberRole.Owner ||
    teamRole === TeamMemberRole.Admin
  ) {
    menuList = [
      {
        name: t('projects.files.rename'),
        iconName: 'Edit',
        isComingSoon: true,
        onSelect: () => {},
      },
      {
        name: t('projects.files.share'),
        iconName: 'Share2',
        onSelect: () => onShare(),
      },
      {
        name: t('projects.files.forward'),
        iconName: 'Forward',
        isComingSoon: true,
        onSelect: () => {},
      },
      {
        name: t('projects.files.rotate'),
        iconName: 'RotateCw',
        isComingSoon: true,
        onSelect: () => {},
      },
      {
        name: t('projects.files.info'),
        iconName: 'Info',
        isComingSoon: true,
        onSelect: () => {},
      },
      {
        name: t('projects.files.delete'),
        iconName: 'Trash2',
        isRed: true,
        isComingSoon: fromChatMessage,
        onSelect: onDeleteFile,
      },
    ];
  } else {
    menuList = [
      {
        name: t('projects.files.rename'),
        iconName: 'Edit',
        isComingSoon: true,
        onSelect: () => {},
      },
      {
        name: t('projects.files.forward'),
        iconName: 'Forward',
        isComingSoon: true,
        onSelect: () => {},
      },
      {
        name: t('projects.files.rotate'),
        iconName: 'RotateCw',
        isComingSoon: true,
        onSelect: () => {},
      },
      {
        name: t('projects.files.info'),
        iconName: 'Info',
        isComingSoon: true,
        onSelect: () => {},
      },
    ];
  }

  return (
    <PopupIcon
      name='MoreVertical'
      color='white'
      popupProps={{
        position: 'left',
        offset: [20, theme.spacing.xxxs],
        menuList,
      }}
    />
  );
};
