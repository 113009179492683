import { useRoute } from '@react-navigation/native';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { useToast } from 'react-native-toast-notifications';

import { CustomToast } from '@components/CustomToast/CustomToast';
import { HeaderGoBack } from '@components/HeaderGoBack/HeaderGoBack';
import { WebModal } from '@components/Modals/WebModal.web';
import { removeProjectLimitSnackBarNextTime } from '@components/Projects/ProjectForm.web';
import { Box, Text } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import ListNavigationItem, {
  ListNavigationItemProps,
} from '@components/shared/ListNavigationItem/ListNavigationItem';
import SearchInput from '@components/shared/SearchInput/SearchInput';
import { TeamCard } from '@components/Team/TeamCard';
import { Member } from '@components/User/Member';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  ListTeamsDocument,
  Team,
  TeamRole,
  useAssignTeamOwnerMutation,
  useDeleteTeamMutation,
  useLeaveTeamMutation,
  useListProductsQuery,
  User,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useTeamFromQuery } from '@hooks/useTeamFromQuery';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { useAppNavigation } from '@navigation/useAppNavigation';
import EditTeamModal from '@screens/Teams/EditTeamModal.web';
import TeamNavList from '@screens/Teams/TeamNavList.web';
import WebModalComponent from '@screens/Teams/WebModalComponent.web';
import { WEB_PLAN_NAME } from '@src/constants/constants';

import type { TeamsStackScreenProps } from '@navigation/teams/teams-stack';

const TeamDetails: React.FC = () => {
  const isPaymentsFeatureActivated = true;
  const { t } = useTranslation();
  const appNavigation = useAppNavigation();
  const { setSelectedTeam, setFromSubscriptionPlanList } =
    useWebDrawerNavigator();
  const route = useRoute<TeamsStackScreenProps<'team-details'>['route']>();
  const { me } = useMe();
  const [teamIdRequested, setTeamIdRequested] = useState('');
  const [showOwnerLeaveMsgBox, setShowOwnerLeaveMsgBox] = useState(false);
  const [showAssignNewOwerBox, setShowAssignNewOwerBox] = useState(false);
  const [showAssignConfirmBox, setShowAssignConfirmBox] = useState(false);
  const [showLeaveDeleteMsgBox, setShowLeaveDeleteMsgBox] = useState(false);
  const [showMemberLeaveMsgBox, setShowMemberLeaveMsgBox] = useState(false);
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
  const [assignUser, setAssignUser] = useState<User | undefined>(undefined);

  const [search, setSearch] = useState<string>('');

  const toast = useToast();

  const { data: listProductsData } = useListProductsQuery();

  const webPlanList = listProductsData?.listProducts.filter((t) =>
    t.name.toLowerCase().includes(WEB_PLAN_NAME)
  );
  const webPlan = webPlanList ? webPlanList[0] : undefined;

  const {
    setUpgradeTeamPlanOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIsCreateProjectOpen,
    setEditProjectId,
    setIdTeamOpen,
    setIsTeamPersonal,
  } = useWebDrawer();

  const {
    params: { teamId },
  } = route || {};

  const { team, loading } = useTeamFromQuery({
    teamId,
  });

  const teamMembersWithoutMe = team?.users?.filter(
    (u) =>
      u.id !== me?.id &&
      ((search.length > 0 &&
        (u?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
          u?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
          u?.name?.toLowerCase().includes(search.toLowerCase()))) ||
        search.length === 0)
  );

  const [leaveTeam] = useLeaveTeamMutation({
    onCompleted: () => appNavigation.goBack(),
    refetchQueries: [{ query: ListTeamsDocument }],
  });

  const [deleteTeam] = useDeleteTeamMutation({
    onCompleted: () => {
      removeProjectLimitSnackBarNextTime(me?.id, '0');
      removeProjectLimitSnackBarNextTime(me?.id, id);
      appNavigation.goBack();
    },
    refetchQueries: [{ query: ListTeamsDocument }],
  });

  const [assignTeamOwner] = useAssignTeamOwnerMutation({
    onCompleted: () => {
      if (!teamId) return;

      leaveTeam({
        variables: {
          id: teamId,
        },
      });
    },
    onError: (error) => {
      console.log(t('shared:error'), error.message);
    },
    refetchQueries: [{ query: ListTeamsDocument }],
  });

  useEffect(() => {
    if (!toast?.show) return;
    if (teamIdRequested === team?.id) return;

    setTeamIdRequested((team as Team)?.id);

    if (team && !team?.personal && !team?.plan?.id && isOwner) {
      showToast(toastContent2());
    }
  }, [loading]);

  const showToast = (content: JSX.Element) => {
    toast.show(
      <CustomToast>
        <TouchableOpacity onPress={() => openTeamPanel(team)}>
          {content}
        </TouchableOpacity>
      </CustomToast>,
      {
        type: 'custom',
        placement: 'bottom',
        duration: 10000,
        animationType: 'zoom-in',
      }
    );
  };

  let storage = webPlan?.features.storage_gb_amount ?? '2G';
  if (webPlan?.features.storage_gb_amount > 1000) {
    storage = (webPlan?.features.storage_gb_amount / 1000).toString() + 'T';
  }

  const projects_amount = webPlan?.features.projects_amount ?? '3';

  const toastContent2 = () => {
    return toastContent(
      t('models:customToast.teamPlan', {
        planName: webPlan?.name ?? WEB_PLAN_NAME,
      }),
      t('models:customToast.upgradePlanTeam', {
        planName: webPlan?.name ?? WEB_PLAN_NAME,
      }),
      t('models:customToast.firstBenefitPlan', { planStorage: storage }),
      t('models:customToast.secondBenefitPlan', {
        projectsAmount:
          projects_amount.charAt(0).toUpperCase() + projects_amount.slice(1),
      })
    );
  };

  const openTeamPanel = (team: Team) => {
    setIsTaskWebPanelOpen(false);
    setIsTaskWebPanelMaximize(false);
    setIdTaskDetailOpen('');
    setIdProjectOfTaskDetailOpen('');
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    setUpgradeTeamPlanOpen(true);
    setIdTeamOpen(team?.id);
    setIsTeamPersonal(team?.personal);
  };

  const toastContent = (
    line1: string,
    line2: string,
    line3: string,
    line4: string
  ) => {
    return (
      <Box maxWidth={300} gap='xxs'>
        <Text color='white' fontWeight='bold'>
          {line1}
        </Text>
        <Box gap='xxxs'>
          <Text color='white'>{line2}</Text>
          <Box
            flexDirection='row'
            alignItems='center'
            gap='xxs'
            marginTop='xxs'>
            <Icon
              width={20}
              height={20}
              variant='l'
              color='white'
              name='Check'
            />
            <Text color='white'>{line3}</Text>
          </Box>
          <Box flexDirection='row' alignItems='center' gap='xxs'>
            <Icon
              width={20}
              height={20}
              variant='l'
              color='white'
              name='Check'
            />
            <Text color='white'>{line4}</Text>
          </Box>
        </Box>
      </Box>
    );
  };

  if (!team?.id && loading) {
    return <ActivityIndicatorLoading />;
  }

  if (!team) {
    return (
      <Box flex={1} paddingTop='m' alignItems='center'>
        <Text>Team not found</Text>
      </Box>
    );
  }

  const { id, personal, owner, usersCount, users } = team;

  const isOwner = me?.id === owner?.id;
  const isAdmin =
    users?.filter(
      (user) => user?.id === me?.id && user.teamRoles?.includes(TeamRole.Admin)
    ) || [];

  const leaveTeamCheck = () => {
    if (isOwner) {
      // show pop up
      if (usersCount === 1) {
        setShowLeaveDeleteMsgBox(true);
      } else {
        setShowOwnerLeaveMsgBox(true);
      }
    } else {
      setShowMemberLeaveMsgBox(true);
    }
  };

  const shouldBeDisplayedWhen = true;

  const goToUpgradeTeam = () => {
    setIsTaskWebPanelOpen(false);
    setIsTaskWebPanelMaximize(false);
    setIdTaskDetailOpen('');
    setIdProjectOfTaskDetailOpen('');
    setIsCreateProjectOpen(false);
    setEditProjectId('');
    setUpgradeTeamPlanOpen(true);
    setIdTeamOpen(id);
    setIsTeamPersonal(team?.personal);
  };
  const webNavItems: ListNavigationItemProps[] =
    shouldBeDisplayedWhen &&
    !['trialing', 'active'].includes(team?.plan?.status as unknown as string) &&
    !team?.personal &&
    isOwner
      ? [
          {
            title: 'Upgrade',
            description: 'Enjoy more features!',
            iconName: 'Zap',
            iconBackgroundColor: 'greenSecondaryMild',
            iconColor: 'greenBrand',
            disabled: false,
            onPress: () => {
              toast.hideAll();
              goToUpgradeTeam();
            },
            comingSoon: false,
          },
        ]
      : [];

  const handleSubscriptionPress = () => {
    setSelectedTeam(team);
    setFromSubscriptionPlanList(false);
    appNavigation.navigateToMyprofileSubscriptionDetail(
      team.id,
      usersCount,
      team.invoices?.length || 0
    );
  };

  const ownerNavItems: ListNavigationItemProps[] =
    isOwner && !team.personal
      ? [
          {
            title: 'Subscription',
            description: 'Manage subscription',
            iconName: 'CreditCard',
            iconBackgroundColor: 'grey01',
            disabled: !isPaymentsFeatureActivated || !team.plan,
            comingSoon: !isPaymentsFeatureActivated,
            onPress: handleSubscriptionPress,
          },
        ]
      : [];

  const navItems = [...webNavItems, ...ownerNavItems];
  if (!personal) {
    navItems.push({
      title: 'Leave',
      titleColor: 'alertRed',
      description: 'Leave the team',
      iconName: 'Leave',
      iconBackgroundColor: 'grey01',
      onPress: leaveTeamCheck,
    });
  }

  const assignNewOwner = () => {
    if (!teamId || !assignUser) return;

    assignTeamOwner({
      variables: {
        id: teamId,
        newOwnerId: assignUser.id,
      },
    });
  };

  const handleOwnerLeaveBoxVisibility = () => {
    setShowOwnerLeaveMsgBox(false);
  };

  const handleLeaveDeleteBoxVisibility = () => {
    setShowLeaveDeleteMsgBox(false);
  };

  const handleMemberLeaveBoxVisibility = () => {
    setShowMemberLeaveMsgBox(false);
  };

  const handleShowCreateTeamModal = () => {
    setShowCreateTeamModal(!showCreateTeamModal);
  };

  const handleOnPressOk = () => {
    if (showOwnerLeaveMsgBox) {
      setShowOwnerLeaveMsgBox(false);
      setShowAssignNewOwerBox(true);
    } else if (showLeaveDeleteMsgBox) {
      setShowLeaveDeleteMsgBox(false);
      if (!id) return;
      deleteTeam({
        variables: { id },
      });
    } else if (showMemberLeaveMsgBox) {
      setShowMemberLeaveMsgBox(false);
      if (!id) return;
      // call mutation to leave the team. onCompleted > goBack();
      leaveTeam({
        variables: {
          id,
        },
      });
    }
  };

  const listHeaderComponent = () => {
    return (
      <Box marginTop='xxs' marginBottom='l'>
        <TeamCard
          team={team}
          onEditPress={() => {
            setShowCreateTeamModal(true);
          }}
          isOwner={isOwner}
          isAdmin={isAdmin.length > 0}
        />
      </Box>
    );
  };

  const listFooterComponent = () => {
    return <Box marginBottom='listFooter' />;
  };

  const itemSeparatorComponent = () => {
    return <Box marginBottom='l' />;
  };

  const renderItem = ({ item, index }) => {
    return (
      <Box key={index}>
        <ListNavigationItem {...item} marginBottom='none' />
      </Box>
    );
  };

  return (
    <Box style={{ width: '100%' }} marginTop='xxxs' paddingLeft='l'>
      <HeaderGoBack redirectTo='myprofile' titleHeader='Team' noSpacing />
      <Box flex={1} alignItems='center'>
        <Box width={776} marginTop='xxxs'>
          <Box marginBottom='l'>
            <TeamNavList
              hasPlan={
                !!team.plan &&
                !(
                  team.plan.isCanceled &&
                  dayjs().isAfter(dayjs(team.plan.expiration))
                )
              }
              isPersonal={team?.personal}
              gotoUpgradePlan={goToUpgradeTeam}
              teamName={team.name}
              teamId={teamId}
              listHeaderComponent={listHeaderComponent}
              itemSeparatorComponent={itemSeparatorComponent}
            />
          </Box>
          <FlatList
            data={navItems}
            renderItem={renderItem}
            ItemSeparatorComponent={itemSeparatorComponent}
            ListFooterComponent={listFooterComponent}
          />

          <WebModalComponent
            modalWidth={483}
            modalHeight={192}
            xVisible={showOwnerLeaveMsgBox}
            msgBody={t('models:teams.leave.message')}
            okButtonTitle={t('models:teams.removeTeamMember.assign')}
            onCloseModal={handleOwnerLeaveBoxVisibility}
            onPressOK={handleOnPressOk}
          />

          <WebModalComponent
            modalWidth={483}
            modalHeight={192}
            xVisible={showLeaveDeleteMsgBox}
            msgBody={t('models:teams.leave.deleteMessage')}
            okButtonTitle={t('shared:delete')}
            onCloseModal={handleLeaveDeleteBoxVisibility}
            onPressOK={handleOnPressOk}
          />
          <WebModalComponent
            modalWidth={483}
            modalHeight={176}
            xVisible={showMemberLeaveMsgBox}
            msgBody={t('models:teams.leave.memberLeaveMessage')}
            okButtonTitle={t('models:teams.leave.title')}
            onCloseModal={handleMemberLeaveBoxVisibility}
            onPressOK={handleOnPressOk}
          />
          <WebModalComponent
            title={t('models:teams.assignNewOwner.title')}
            modalWidth={483}
            xVisible={showAssignConfirmBox}
            msgBody={t('models:teams.assignNewOwner.message', {
              member: assignUser?.name,
            })}
            okButtonTitle={t('models:teams.removeTeamMember.assign')}
            onCloseModal={() => setShowAssignConfirmBox(false)}
            onPressOK={assignNewOwner}
          />
          <WebModal
            accessibilityLabel={t(
              'models:profile.subscriptionPlans.assignNewOwner'
            )}
            marginTop='m'
            visible={showAssignNewOwerBox}
            width={504}
            height={512}
            title={t('models:profile.subscriptionPlans.assignNewOwner')}
            titleVariant='labelLarge'
            titleColor='textSecondary'
            marginHorizontal='l'
            onClose={() => {
              setShowAssignNewOwerBox(false);
            }}>
            <Box flex={1}>
              <Box
                marginHorizontal='m'
                marginTop='m'
                flexDirection='row'
                alignItems='center'>
                <SearchInput
                  onTextChanged={(t: string) => setSearch(t)}
                  value={search}
                />
              </Box>
              <FlatList
                data={teamMembersWithoutMe}
                renderItem={({ item }) => (
                  <TouchableOpacity
                    onPress={() => {
                      setShowAssignNewOwerBox(false);
                      setAssignUser(item);
                      setShowAssignConfirmBox(true);
                    }}>
                    <Box paddingHorizontal='m' paddingVertical='xs'>
                      <Member member={item} highlightedValue={search} />
                    </Box>
                  </TouchableOpacity>
                )}
                ListHeaderComponent={<Box marginTop='xs' />}
                ListFooterComponent={<Box marginTop='m' />}
                ListEmptyComponent={
                  <Box m='m'>
                    <Text textAlign='center' color='grey04'>
                      {t('models:teams.assignNewOwner.noOtherMembers')}
                    </Text>
                  </Box>
                }
                keyExtractor={(item) => item.id}
              />
            </Box>
          </WebModal>
          <EditTeamModal
            team={team}
            showCreateTeamModal={showCreateTeamModal}
            setShowCreateTeamModal={handleShowCreateTeamModal}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TeamDetails;
