import { InternalRefetchQueriesInclude } from '@apollo/client';
import { Platform } from 'react-native';

import {
  Chat,
  ListMessagesDocument,
  ProjectDocument,
  useDeleteProjectFilesMutation,
} from '@graphql/generated';

export const useDeleteProjectDocument = () => {
  const [deleteProjectFile] = useDeleteProjectFilesMutation();

  const deleteProjectFileById = (
    document: ProjectDocument[],
    chatId: Chat['id'],
    onComplete?: () => void
  ) => {
    const docids = document?.map((doc) => doc.id);
    const refetchList:
      | InternalRefetchQueriesInclude
      | (() => InternalRefetchQueriesInclude)
      | undefined = ['listProjectDocuments', 'listTaskDocuments'];
    if (Platform.OS !== 'web') {
      refetchList.push('getProject');
      if (chatId) {
        refetchList.push({
          query: ListMessagesDocument,
          variables: { chatId: chatId },
        });
      }
    }
    deleteProjectFile({
      variables: { ids: docids },
      onCompleted: onComplete,
      refetchQueries: refetchList,
      update(cache) {
        document.forEach((doc) => {
          const cacheIdentifier = cache.identify(doc);
          cache.evict({ id: cacheIdentifier });
          cache.gc();
        });
      },
    });
  };

  return { deleteProjectFileById };
};
