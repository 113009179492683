/* eslint-disable no-nested-ternary */
import dayjs from 'dayjs';
import debounce from 'lodash.debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TouchableWithoutFeedback } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import uuid from 'react-native-uuid';

import Avatar from '@components/Avatar/Avatar';
import Box from '@components/Box/Box';
import ChatImages from '@components/Chat/ChatImages';
import { CheckInCard } from '@components/Chat/CheckInCard';
import DocumentCard from '@components/Chat/DocumentCard';
import ForwardMessage from '@components/Chat/ForwardMessage';
import MessageParts from '@components/Chat/MessageParts';
import { ReplyMessage } from '@components/Chat/ReplyMessage';
import TagsCollection from '@components/Chat/TagsCollection';
import { TaskAssigned } from '@components/Chat/TaskAssigned';
import { TaskCompleted } from '@components/Chat/TaskCompleted';
import { VoiceCard } from '@components/Chat/VoiceCard';
import { Hoverable } from '@components/Hoverable/index.web';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import Icon from '@components/shared/Icon/Icon';
import { Tag } from '@components/shared/Tags/Tag';
import Text from '@components/Text/Text';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  Attachment,
  Chat,
  Document,
  ListMessagesDocument,
  ListMessagesQuery,
  LocalFile,
  Message,
  MessageReaction,
  useCreateMessageIndividualMutation,
  useCreateMessageReactionMutation,
  useDeleteFriendRequestMutation,
  User,
  useRemoveMessageReactionMutation,
} from '@graphql/generated';
import useChatProcessing from '@hooks/useChatProcessing';
import { useDeleteMessage } from '@hooks/useDeleteMessage';
import useEmoji from '@hooks/useEmoji';
import useMe from '@hooks/useMe';
import { usePreviewDocuments } from '@hooks/usePreviewDocuments';
import { ImagesPreview } from '@screens/Chats/ImagesPreview.web';
import Images from '@themes/images';
import theme, { Theme } from '@themes/theme';
import { openInNewTab } from '@utils/fileUtils';
import { triggersConfig } from '@utils/mentions';
import { remoteConfigValues } from '@utils/remoteConfigValues';
import { generateTagCollections } from '@utils/tagCollectionService';

export type ChatMessageType = NonNullable<
  NonNullable<
    NonNullable<NonNullable<ListMessagesQuery['listMessages']>['edges']>[number]
  >['node']
> & {
  id: string;
  avatarUrl: string;
  attachments: Attachment[];
  name: string;
  isSender: boolean;
  createdAt: Date;
  tag?:
    | 'Task Completed'
    | 'Task Assigned'
    | 'Checked In'
    | 'Media Attached'
    | 'File Attached';
};

type TagType = {
  id: number;
  icon: keyof typeof Images;
  value: string;
  color: 'infoSecondary' | 'secondary' | 'primary' | 'tertiary';
  iconColor: keyof Theme['colors'];
};

const TAG_LIST: TagType[] = [
  {
    id: 0,
    icon: 'Check',
    value: 'Task Completed',
    color: 'secondary',
    iconColor: 'greenSecondary',
  },
  {
    id: 1,
    icon: 'Clipboard',
    value: 'Task Assigned',
    color: 'tertiary',
    iconColor: 'magentaLight',
  },
  {
    id: 2,
    icon: 'MapPin',
    value: 'Checked In',
    color: 'primary',
    iconColor: 'blue',
  },
  {
    id: 3,
    icon: 'Image',
    value: 'Media Attached',
    color: 'infoSecondary',
    iconColor: 'orange',
  },
  {
    id: 4,
    icon: 'Paperclip',
    value: 'File Attached',
    color: 'infoSecondary',
    iconColor: 'orange',
  },
];

interface ChatMessageProps {
  message: Message;
  chatData?: Chat;
  filterVal?: string;
  isActivityItem?: boolean;
  onPress?: () => void;
  onLongPress?: () => void;
  onEditTagPress: () => void;
  onForwardPress: () => void;
  highlighted?: boolean;
  highlightedToGrey01?: boolean;
  showNotPaidModal?: (file: Document) => void;
  hideSenderInfo?: boolean;
  showOnlyTime?: boolean;
  onRightClick?: (message: Message, mouseX: number, mouseY: number) => void;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  chatData,
  filterVal,
  isActivityItem,
  onPress,
  onLongPress,
  highlighted = false,
  highlightedToGrey01 = false,
  showNotPaidModal,
  hideSenderInfo = false,
  showOnlyTime = false,
  onRightClick,
}) => {
  const { setPreviewDocuments, setActiveIndex, setTagsCollections } =
    usePreviewDocuments();
  const [isHoverVisible, setHoverVisible] = useState(false);
  const [isImageCarouselVisible, setImageCarouselVisible] = useState(false);
  const { t } = useTranslation('format');
  const [isEditPopVisible, setEditPopVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [highlightedToGrey02, setHighlightedToGrey02] = useState(false);
  const { me } = useMe();
  const { toggleEmojiPickerOpened, setFromValue, setMessageId } = useEmoji();
  const { chatDrawerWidth } = useWebDrawer();

  const mainRef = useRef<HTMLElement>(null);

  const handleContextMenu = (e: {
    preventDefault: () => void;
    pageX: any;
    pageY: any;
  }) => {
    e.preventDefault();
    onRightClick && onRightClick(message, e.pageX, e.pageY);
  };

  useEffect(() => {
    const element = mainRef.current;
    if (element) {
      element.addEventListener('contextmenu', handleContextMenu);
      return () => {
        element.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [handleContextMenu]);

  useEffect(() => {
    setHighlightedToGrey02(highlightedToGrey01);
  }, [highlightedToGrey01]);

  const {
    body,
    isSender,
    createdAt,
    clientId,
    authorId,
    attachments,
    replyMessage,
    publishedAt,
    failedAt = null,
    readReceipts,
    checkin,
    reaction,
    replyId,
    isDraft,
    isRetry,
    author,
  } = message;
  const failedMessage = remoteConfigValues().chat_failed_messages_indicator
    ? failedAt ||
      (createdAt && !publishedAt && dayjs().diff(dayjs(createdAt), 'second')) >=
        60
    : false;

  const response = chatData?.getChat?.users.filter((item) => {
    return item.id === authorId;
  });

  const authorFirstName =
    (response && response[0]?.firstName) || author.firstName;
  const authorLastName = (response && response[0]?.lastName) || author.lastName;
  const authorName = authorFirstName + ' ' + authorLastName;
  const avatar = (response && response[0]?.avatar) || author.avatar;
  const id = (response && response[0]?.id) || author.id;

  const { retrySendFailedMessage } = useChatProcessing();
  const isForwarded = false;

  const tagsByAuthorCollections = generateTagCollections(attachments || []);
  const isDocumentType = (item: Attachment): item is Document =>
    item.__typename === 'Document';

  const documentAttachments: Document[] = attachments?.filter((item) => {
    return isDocumentType(item);
  }) as Document[];

  const audioAttachments = documentAttachments.filter((item) => item.isAudio);
  const imageAttachments = documentAttachments.filter((item) => item.isImage);
  const fileAttachments = documentAttachments.filter(
    (item) => !item.isImage && !item.isAudio
  );

  const taskAssignments = attachments?.filter((item) => {
    return item.__typename === 'MessageTaskAssignment';
  });

  const isAssignTask = taskAssignments && taskAssignments.length > 0;

  const taskCompletes = attachments?.filter((item) => {
    return item.__typename === 'MessageTaskComplete';
  });

  const isCompleteTask = taskCompletes && taskCompletes.length > 0;

  const connectionRequestMessage = 'Sent you a connection request';

  const { deleteMessageById } = useDeleteMessage();

  const onCompleted = () => {
    setDeleteModalVisible(false);
  };

  const [removeMessageReactionMutation] = useRemoveMessageReactionMutation({
    refetchQueries: [
      { query: ListMessagesDocument, variables: { chatId: message.chatId } },
      'listMessages',
    ],
  });

  const [createMessageReactionMutation] = useCreateMessageReactionMutation({
    refetchQueries: [
      { query: ListMessagesDocument, variables: { chatId: message.chatId } },
      'listMessages',
    ],
  });

  const updateEmoji = (xitem: MessageReaction) => {
    if (xitem.user?.some((t: User) => t.id === me?.id)) {
      removeMessageReactionMutation({
        variables: {
          messageReactionId: xitem.id,
        },
      });
    } else {
      createMessageReactionMutation({
        variables: {
          messageId: message.id,
          reaction: xitem.reaction,
        },
      });
    }
  };

  const [createMessageIndividual] = useCreateMessageIndividualMutation({
    refetchQueries: ['listUsers'],
  });
  const [deleteFriendRequest] = useDeleteFriendRequestMutation({
    refetchQueries: ['listChats', 'listUsers'],
  });
  const chatId = chatData?.getChat?.id;

  const isReceiverFriendRequest =
    !chatData?.getChat?.friendRequestAccepted &&
    chatData?.getChat.owner.id !== me?.id;

  const [showAcceptPopup, setShowAcceptPopUp] = useState(
    isReceiverFriendRequest
  );

  const onAcceptPress = () => {
    chatId &&
      createMessageIndividual({
        variables: {
          attributes: {
            body: 'Connection request approved',
            chatId: chatId,
            clientId: uuid.v4().toString(),
            projectIds: [],
            replyId: null,
            taskIds: [],
            publish: true,
            checkin: null,
            friendRequestAccepted: true,
          },
        },
      });
  };

  const { toggleChatListOpen } = useWebDrawer();

  const onDeclinePress = () => {
    chatId &&
      deleteFriendRequest({
        onCompleted: () => {
          toggleChatListOpen();
        },
        variables: {
          friendRequest: true,
          id: chatId,
        },
      });
  };

  const debouncedUpdateEmoji = useMemo(() => debounce(updateEmoji, 500), []);

  const getTag = () => {
    const tag = '';

    const aTag: TagType | undefined = TAG_LIST.find((t) => t.value == tag);
    if (aTag && !isForwarded) {
      return (
        <Tag
          label={aTag.value}
          labelVariant='metadata'
          variant={aTag.color}
          prefixMarginRight='xxs'
          prefix={<Icon name={aTag.icon} variant='xs' color={aTag.iconColor} />}
          padding='xxs'
          borderRadius='xxs'
        />
      );
    }
  };

  const messageContent = (
    <>
      {replyMessage && (
        <TouchableOpacity
          onLongPress={() => {
            onLongPress && onLongPress();
          }}
          onPress={onPress}>
          <Box marginTop='xxs' accessibilityLabel='Message'>
            <ReplyMessage message={replyMessage} />
          </Box>
        </TouchableOpacity>
      )}

      {!!body?.trim() && !isActivityItem && (
        <TouchableWithoutFeedback
          onLongPress={() => {
            onLongPress && onLongPress();
          }}
          onPress={replyId ? onPress : undefined}>
          <Box marginBottom='xxxs' alignSelf='flex-start'>
            <MessageParts
              value={body}
              configs={[triggersConfig.mention]}
              styleLinks
              filterVal={filterVal}
              textColor={failedMessage ? 'onBackgroundTertiary' : undefined}
            />
          </Box>
        </TouchableWithoutFeedback>
      )}

      {audioAttachments.map((document) => (
        <Box key={document.id}>
          <VoiceCard
            onLongPress={() => {
              onLongPress && onLongPress();
            }}
            key={document.id}
            voice={document}
          />
        </Box>
      ))}

      {imageAttachments.length > 0 && (
        <Box>
          <ChatImages
            isForwarded={isForwarded}
            onPress={(image: Document | LocalFile) => {
              const isPaid =
                showNotPaidModal && showNotPaidModal(image as Document);
              if (isPaid) return;
              setPreviewDocuments(
                imageAttachments.map((item) => ({
                  ...item,
                  owner: (response && response[0]) || author,
                }))
              );
              setActiveIndex(
                imageAttachments.findIndex((i) => i.id === image.id)
              );
              setTagsCollections(tagsByAuthorCollections);
              return setImageCarouselVisible(true);
            }}
            onLongPress={() => {
              onLongPress && onLongPress();
            }}
            list={imageAttachments}
          />
        </Box>
      )}

      {fileAttachments?.map((document) => (
        <Box key={document.id}>
          <DocumentCard
            key={document.id}
            document={document}
            onPress={() => {
              const isPaid = showNotPaidModal && showNotPaidModal(document);
              if (isPaid) return;
              return openInNewTab(document);
            }}
            onLongPress={() => {
              onLongPress && onLongPress();
            }}
          />
        </Box>
      ))}

      {!isAssignTask &&
        !isCompleteTask &&
        tagsByAuthorCollections.map((tagCollections, index) => {
          return (
            <Box key={index}>
              {tagCollections.map((item, index) => {
                const { project, tasks, author } = item;
                return (
                  <Box marginBottom='xs' key={index}>
                    <TagsCollection
                      onLongPress={() => {
                        onLongPress && onLongPress();
                      }}
                      tagsCollection={{ project, tasks, author }}
                      showAuthor={false}
                    />
                  </Box>
                );
              })}
            </Box>
          );
        })}
      {isReceiverFriendRequest &&
        showAcceptPopup &&
        body === connectionRequestMessage && (
          <Box
            flexDirection='row'
            flex={1}
            pointerEvents='box-none'
            marginVertical='xs'
            paddingHorizontal='m'
            justifyContent='space-between'>
            <Box marginRight='m'>
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => {
                  setShowAcceptPopUp(false);
                  onDeclinePress();
                }}>
                <Box
                  borderColor='textPrimary'
                  borderWidth={1}
                  borderRadius='xs'
                  paddingHorizontal='l'
                  accessibilityLabel='Decline Friend Request'
                  paddingVertical='xxs'>
                  <Text color='black' variant='buttonLabel'>
                    Decline
                  </Text>
                </Box>
              </TouchableOpacity>
            </Box>
            <Box>
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => {
                  setShowAcceptPopUp(false);
                  onAcceptPress();
                }}>
                <Box
                  backgroundColor='black'
                  borderWidth={1}
                  flexDirection='row'
                  borderRadius='xs'
                  accessibilityLabel='Accept Friend Request'
                  alignItems='center'
                  paddingHorizontal='m'
                  paddingVertical='xxs'>
                  <Icon name='Check' color='white' marginRight='xxs' />
                  <Text color='white' variant='buttonLabel'>
                    Accept
                  </Text>
                </Box>
              </TouchableOpacity>
            </Box>
          </Box>
        )}

      {isForwarded ? (
        <></>
      ) : body?.trim() ? (
        isAssignTask && (
          <Box width={isSender ? 354 : 382}>
            <TaskAssigned message={message} />
          </Box>
        )
      ) : (
        isAssignTask && (
          <Box width={382}>
            <TaskAssigned message={message} />
          </Box>
        )
      )}
      {isForwarded ? (
        <></>
      ) : body?.trim() ? (
        isCompleteTask && (
          <Box width={isSender ? 354 : 382}>
            <TaskCompleted message={message} />
          </Box>
        )
      ) : (
        isCompleteTask && (
          <Box width={382}>
            <TaskCompleted message={message} />
          </Box>
        )
      )}
      {checkin && (
        <CheckInCard checkin={checkin} __typename='check in'></CheckInCard>
      )}

      {isImageCarouselVisible && (
        <ImagesPreview
          fromChatMessage={true}
          chatId={chatId}
          onDismiss={() => setImageCarouselVisible(false)}
        />
      )}
    </>
  );

  const forwardedMessage = <ForwardMessage>{messageContent}</ForwardMessage>;

  const reSendMessage = () => {
    retrySendFailedMessage(clientId, authorId);
  };
  if (isActivityItem) return messageContent;

  const showReadReceipts = readReceipts?.filter((item) => item.id !== me?.id);

  return (
    <Hoverable
      style={{ flex: 1 }}
      onHoverIn={() => {
        setHoverVisible(true);
        setEditPopVisible(false);
      }}
      onHoverOut={() => {
        setHoverVisible(false);
      }}>
      {() => (
        <TouchableWithoutFeedback
          onLongPress={() => {
            onLongPress && onLongPress();
          }}
          accessible={false}>
          <Box
            ref={mainRef}
            flexDirection='row'
            alignItems='flex-start'
            paddingHorizontal='m'
            paddingTop='xxs'
            paddingBottom='xxs'
            style={{
              backgroundColor: highlighted
                ? theme.colors.yellowBright
                : isEditPopVisible || highlightedToGrey02
                ? theme.colors.grey02
                : isHoverVisible
                ? 'rgba(232, 232, 232, 0.3)'
                : 'transparent',
            }}>
            {!hideSenderInfo && (
              <Avatar
                id={id}
                avatar={avatar}
                skipCache={true}
                label={`${authorName}`}
                size='small'
              />
            )}
            {hideSenderInfo && <Box width={32} height={32}></Box>}

            <Box flex={1} flexDirection='row' alignItems='flex-start'>
              <Box marginLeft='s' flex={1}>
                {!hideSenderInfo && (
                  <Box
                    alignItems='center'
                    flexDirection='row'
                    justifyContent='space-between'
                    height={32}>
                    <Text variant='webSmall' color='textPrimary'>
                      {authorName}
                    </Text>

                    <Box flex={1} flexDirection='row'>
                      {publishedAt && (
                        <Text
                          variant='metadataSecondary'
                          color='textSecondary'
                          marginLeft='xxs'>
                          {t('time', { val: publishedAt })}
                        </Text>
                      )}
                    </Box>
                  </Box>
                )}
                {hideSenderInfo && showOnlyTime && (
                  <Box
                    alignItems='center'
                    flexDirection='row'
                    justifyContent='space-between'
                    marginBottom='xxs'>
                    <Box flex={1} flexDirection='row'>
                      <Text
                        variant='metadataSecondary'
                        color='textSecondary'
                        marginLeft='xxs'>
                        {t('time', { val: publishedAt })}
                      </Text>
                    </Box>
                  </Box>
                )}

                <Box flexDirection='row' alignItems='flex-end'>
                  {isForwarded ? (
                    forwardedMessage
                  ) : body?.trim() ? (
                    <Box
                      accessibilityLabel='Message'
                      paddingVertical='xs'
                      paddingHorizontal='m'
                      borderBottomLeftRadius='s'
                      borderBottomRightRadius='s'
                      borderTopRightRadius='s'
                      alignSelf='flex-start'
                      maxWidth={
                        isSender ? chatDrawerWidth - 120 : chatDrawerWidth - 92
                      }
                      style={{
                        backgroundColor: isSender
                          ? theme.colors.greenSecondaryMild
                          : theme.colors.white,
                      }}>
                      {messageContent}
                    </Box>
                  ) : (
                    <Box
                      accessibilityLabel='Message'
                      alignSelf='flex-start'
                      paddingHorizontal='m'
                      borderRadius='s'
                      maxWidth={chatDrawerWidth - 92}
                      style={{
                        backgroundColor: isSender
                          ? theme.colors.greenSecondaryMild
                          : theme.colors.white,
                      }}>
                      {messageContent}
                    </Box>
                  )}

                  <Box marginBottom='xxs'>
                    {!publishedAt && !failedMessage && !isRetry && (
                      <Box marginStart='xxs'>
                        <Icon variant='xxxs' name='Clock' color='grey05' />
                      </Box>
                    )}

                    {showReadReceipts && showReadReceipts.length > 0 && (
                      <Icon
                        variant='s'
                        name='Read'
                        color='greenSecondary'
                        marginLeft='xs'
                      />
                    )}
                    {publishedAt &&
                      !failedMessage &&
                      !(showReadReceipts && showReadReceipts.length > 0) &&
                      isSender &&
                      createdAt && (
                        <Icon
                          variant='s'
                          name='Check'
                          color='grey04'
                          marginLeft='xs'
                        />
                      )}
                  </Box>
                </Box>
                {isDraft && isRetry && (
                  <Box marginStart='xxs' flexDirection='row' marginTop='s'>
                    <Icon variant='l' name='Retry' color='alertRed' />
                    <TouchableOpacity onPress={reSendMessage}>
                      <Text variant='metadata' color='alertRed'>
                        Failed to send. Click here to retry.
                      </Text>
                    </TouchableOpacity>
                  </Box>
                )}
                <Box
                  flexDirection='row'
                  flexWrap='wrap'
                  alignItems='center'
                  mt='xxs'>
                  {(reaction ?? [])
                    .filter((t) => t.count > 0)
                    .map((item, index) => {
                      return (
                        <TouchableOpacity
                          key={index}
                          onPress={async () => {
                            debouncedUpdateEmoji(item);
                          }}>
                          <Box
                            flexDirection='row'
                            borderRadius='xxs'
                            style={{ cursor: 'pointer' }}
                            marginRight={
                              index ===
                              (reaction ?? []).filter((t) => t.count > 0)
                                .length -
                                1
                                ? 'm'
                                : 'xxs'
                            }
                            marginBottom='xxs'
                            p='xxs'
                            backgroundColor='grey01'>
                            <Text>{item.reaction}</Text>
                            <Box paddingHorizontal='xxs'>
                              <Text>{item.count}</Text>
                            </Box>
                          </Box>
                        </TouchableOpacity>
                      );
                    })}
                  {(reaction ?? []).filter((t) => t.count > 0).length > 0 && (
                    <Icon
                      width={28}
                      height={28}
                      name='PlusBold'
                      marginBottom='xxs'
                      onPress={() => {
                        setFromValue('MessageReaction');
                        setMessageId(message.id);

                        toggleEmojiPickerOpened(true);
                      }}
                    />
                  )}
                </Box>
                {getTag()}
                {isImageCarouselVisible && (
                  <ImagesPreview
                    fromChatMessage={true}
                    chatId={chatId}
                    onDismiss={() => setImageCarouselVisible(false)}
                  />
                )}

                {isDeleteModalVisible && (
                  <ConfirmModal
                    showModal={isDeleteModalVisible}
                    onClose={() => setDeleteModalVisible(false)}
                    onPress={() => deleteMessageById(message, onCompleted)}
                    buttonText={t('shared:delete')}
                    title={t('models:chat.delete.message.cta')}
                    message={t('models:chat.delete.message.message')}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </TouchableWithoutFeedback>
      )}
    </Hoverable>
  );
};

export default ChatMessage;
