import { StackActions } from '@react-navigation/native';
import * as Clipboard from 'expo-clipboard';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { Box } from '@components/Restyle';
import ListNavigationItem, {
  ListNavigationItemProps,
} from '@components/shared/ListNavigationItem/ListNavigationItem';
import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import {
  useCreateTeamInvitationMutation,
  useSetPrimaryTeamMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useAppNavigation } from '@navigation/useAppNavigation';

interface TeamNavListProps {
  teamName: string;
  teamId: string;
  hasPlan: boolean;
  isPersonal: boolean;
  listHeaderComponent: () => JSX.Element;
  itemSeparatorComponent: () => JSX.Element;
  gotoUpgradePlan?: () => void;
}

const TeamNavList: React.FC<TeamNavListProps> = ({
  teamName,
  teamId,
  listHeaderComponent,
  itemSeparatorComponent,
  hasPlan,
  isPersonal,
}) => {
  const appNavigation = useAppNavigation();
  const toast = useToast();
  const push = StackActions.push;
  const { t } = useTranslation();

  const {
    setIsTaskWebPanelOpen,
    setIdTaskDetailOpen,
    setIdProjectOfTaskDetailOpen,
    setIsTaskWebPanelMaximize,
    setIsCreateProjectOpen,
    setEditProjectId,
    setUpgradeTeamPlanOpen,
    setIdTeamOpen,
    setIsTeamPersonal,
  } = useWebDrawer();

  const [createTeamInvitationMutation] = useCreateTeamInvitationMutation({
    onCompleted: ({
      createTeamInvitation,
    }: {
      createTeamInvitation: string;
    }) => {
      if (createTeamInvitation) {
        const formattedLink =
          createTeamInvitation.replace(
            'register/register-with-email',
            'invited-user'
          ) + `&type=team`;
        copyToClipboard(formattedLink);
      }
    },
  });

  const handleInviteToTeamPress = () => {
    createTeamInvitationMutation({ variables: { id: teamId } });
  };

  const copyToClipboard = async (link: string) => {
    if (!link) return; // Early return if link is falsy
    const inviteLink = `You’re invited to join our new team at ${teamName}. Click the link below to get started: `;
    await Clipboard.setStringAsync(inviteLink + link);
    navigator.share({
      title: inviteLink,
      url: link,
    });
    toast.show('Copied', {
      duration: 2000,
    });
  };
  const [setPrimaryTeam] = useSetPrimaryTeamMutation();
  const { me } = useMe();
  const isPrimary = me?.primaryTeamId === teamId;
  const [showModal, setShowModal] = useState(false);

  const primaryTeamCheck = () => {
    console.log('primaryTeamCheck');
    setShowModal(true);
  };

  const membersItem: ListNavigationItemProps[] = isPersonal
    ? []
    : [
        {
          title: 'Members',
          description: 'Team Members',
          iconName: 'Users',
          iconBackgroundColor: 'grey01',
          onPress: () => {
            if (hasPlan) {
              appNavigation.dispatch(push('team-members', { teamId: teamId }));
            } else {
              setIsTaskWebPanelOpen(false);
              setIsTaskWebPanelMaximize(false);
              setIdTaskDetailOpen('');
              setIdProjectOfTaskDetailOpen('');
              setIsCreateProjectOpen(false);
              setEditProjectId('');
              setUpgradeTeamPlanOpen(true);
              setIdTeamOpen(teamId ?? '');
              setIsTeamPersonal(isPersonal ?? false);
            }
          },
        },
      ];

  const inviteToTeamNavItems: ListNavigationItemProps[] =
    !isPersonal && hasPlan
      ? [
          {
            title: 'Invite to Team',
            description: 'Invite to team via link',
            iconName: 'Link',
            iconBackgroundColor: 'grey01',
            disabled: !hasPlan,
            comingSoon: false,
            onPress: handleInviteToTeamPress,
          },
        ]
      : [];

  const allNavItems: ListNavigationItemProps[] = [
    ...membersItem,
    ...(!isPrimary
      ? [
          {
            title: 'Set up as Primary',
            description: 'Default Team for new projects',
            iconName: 'Star',
            disabled: !hasPlan,
            iconBackgroundColor: 'grey01',
            onPress: () => primaryTeamCheck(),
          },
        ]
      : []),
    {
      title: 'Analytics',
      description: 'See how the storage is used',
      iconName: 'PieChart',
      iconBackgroundColor: 'grey01',
      disabled: false,
      comingSoon: false,
      onPress: () => {
        appNavigation.navigate('team-analytics', {
          teamId: teamId,
        });
      },
    },
    ...inviteToTeamNavItems,
    {
      title: 'Preferences',
      description: 'Manage preferences',
      iconName: 'Key',
      iconBackgroundColor: 'grey01',
      disabled: true,
      comingSoon: true,
    },
  ];

  const renderItem = ({ item, index }) => {
    return (
      <Box key={index}>
        <ListNavigationItem {...item} marginBottom='none' />
      </Box>
    );
  };

  return (
    <>
      <FlatList
        data={allNavItems}
        renderItem={renderItem}
        ItemSeparatorComponent={itemSeparatorComponent}
        ListHeaderComponent={listHeaderComponent}
      />
      {showModal && (
        <ConfirmModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          onPress={() => {
            setPrimaryTeam({
              variables: {
                accountId: teamId,
              },

              refetchQueries: ['listTeams'],
            });
            setShowModal(false);
          }}
          buttonText={t('shared:Set')}
          title={t('models:teams.primaryTeam.title')}
          message={t('models:teams.primaryTeam.setMessage', {
            teamName: teamName,
          })}
        />
      )}
    </>
  );
};

export default TeamNavList;
