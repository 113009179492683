import { useNavigation } from '@react-navigation/native';
import fetch from 'cross-fetch';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, TouchableOpacity } from 'react-native';

import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { Box, ShadowBox, Text } from '@components/Restyle';
import Icon from '@components/shared/Icon/Icon';
import { RegisterStackScreenProps } from '@navigation/auth/register-stack-new.web';
import { RegisterFormValues } from '@screens/Auth/RegisterForm.web';
import { RegisterWithPhoneNumberFormValues } from '@screens/Auth/RegisterWithPhoneNumberForm';
import VerificationCodeInputNewWeb from '@screens/Auth/VerificationCodeInputNew.web';
import theme from '@themes/theme';
import { getApiConfig } from '@utils/getApiConfig';

const apiConfig = getApiConfig();
const RegisterWithPhoneVerifycodeNew: React.FC<
  RegisterStackScreenProps<'register-with-phone-verify-code'>
> = ({ route }) => {
  const navigation = useNavigation();
  const { t } = useTranslation('shared');

  const { values, setFieldValue, submitForm } = useFormikContext<
    RegisterWithPhoneNumberFormValues | RegisterFormValues
  >();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [verifyCodeFinish, setVerifyCodeFinish] = useState<boolean>(false);
  const [verifyCodeError, setVerifyCodeError] = useState<boolean>(false);

  const verifyVerificationCode = async (code: string) => {
    setLoading(true);
    const verifyVerificationCodeURL = `/api/v2/verify_verification_code.json`;
    const userPayload = {
      phone_number: values.phone,
      verification_code: code,
    };
    const authEndpoint = `${apiConfig.apiUrl}${verifyVerificationCodeURL}`;

    await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        setLoading(false);
        setVerifyCodeFinish(true);
        if (response?.error) {
          setVerifyCodeError(true);
        } else {
          setVerifyCodeError(false);
          if (response.success) {
            stopCountdown();
            if (route.params.isRegiserWithPhoneNumber) {
              navigation.navigate('register-with-phone-profile');
            } else {
              const teamInvitationCode =
                window.sessionStorage.getItem('invitation_token');
              if (teamInvitationCode) {
                window.sessionStorage.setItem(
                  'do_not_show_invite_message',
                  '1'
                );
                submitForm();
              } else {
                navigation.navigate('create-team');
              }
            }
          } else {
            setErrorMsg('Verify Code Method failed.');
          }
        }
      })
      .catch((err) => {
        setErrorMsg(err);
      });
  };
  const sendVerificationCode = async () => {
    setFieldValue('verify_code', '');
    setLoading(true);
    const sendVerificationCodeURL = `/api/v2/send_verification_code.json`;
    const userPayload = {
      phone_number: values.phone,
    };
    const authEndpoint = `${apiConfig.apiUrl}${sendVerificationCodeURL}`;

    await fetch(authEndpoint, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userPayload),
    })
      .then((response) => response.json())
      .then(async (response) => {
        setLoading(false);
        if (response?.error) {
          setErrorMsg(response?.error);
        } else {
          if (response.success) {
            startCountdown();
          } else {
            setErrorMsg('Send verify code Method failed.');
          }
        }
      })
      .catch((err) => {
        setErrorMsg(err);
      });
  };

  const formatPhoneNumber = (xphoneNumber: string) => {
    const phoneNumber = xphoneNumber.replace(/\D/g, '');
    if (phoneNumber.startsWith('1')) {
      return phoneNumber.length == 11
        ? '(' +
            phoneNumber.substring(1, 4) +
            ')' +
            phoneNumber.substring(4, 7) +
            '-' +
            phoneNumber.substring(7)
        : '(' +
            phoneNumber.substring(0, 3) +
            ')' +
            phoneNumber.substring(3, 6) +
            '-' +
            phoneNumber.substring(6);
    } else {
      return phoneNumber;
    }
  };

  const [countdown, setCountdown] = useState(30);
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    let _timer: NodeJS.Timer;

    if (isActive && countdown > 0) {
      _timer = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsActive(false);
    }

    return () => clearInterval(_timer);
  }, [isActive, countdown]);

  const startCountdown = () => {
    setCountdown(30);
    setIsActive(true);
  };

  const stopCountdown = () => {
    setIsActive(false);
  };

  useEffect(() => {
    setFieldValue('step', route.params.isRegiserWithPhoneNumber ? 2 : 3);
    sendVerificationCode();
  }, []);

  useEffect(() => {
    const handleBackButton = () => {
      setFieldValue('step', route.params.isRegiserWithPhoneNumber ? 1 : 2);
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const borderColor = verifyCodeError
    ? theme.colors.alertRed
    : theme.colors.greenSecondary;

  return (
    <Box
      flex={1}
      backgroundColor='grey01'
      alignItems='center'
      justifyContent='center'>
      <ShadowBox
        variant='base'
        backgroundColor='white'
        borderRadius='m'
        style={{
          width: 440,
        }}>
        <Box
          alignItems='center'
          borderRadius='m'
          px='xl'
          mx='xxs'
          py='l'
          my='xs'
          backgroundColor='white'>
          <Icon name='Logo' width={142} height={43} marginBottom='xl' />
          {loading && <ActivityIndicator />}
          <Text variant='heading2' marginTop='xxs' marginBottom='l'>
            {t('models:onBoarding.verifyYourPhoneNumber')}
          </Text>
          <Text
            variant='webBodySecondary'
            style={{ color: theme.colors.textSecondary }}>
            {t('enterThe4-digitCode', { phoneNumber: '' })}
          </Text>
          <Text
            variant='webBodySecondary'
            marginBottom='s'
            style={{ color: theme.colors.textSecondary }}>
            {formatPhoneNumber(values.phone)}
          </Text>
          <Box
            flexDirection='row'
            alignItems='center'
            alignSelf='center'
            style={{ marginBottom: 40 }}>
            <VerificationCodeInputNewWeb
              inputSize={4}
              verifyCodeFinish={verifyCodeFinish}
              setVerifyCodeError={setVerifyCodeError}
              setVerifyCodeFinish={setVerifyCodeFinish}
              borderColor={verifyCodeFinish ? borderColor : undefined}
              onTextChanged={(text) => {
                setFieldValue('verify_code', text);
                if (text.length == 4) {
                  verifyVerificationCode(text);
                }
              }}
            />
          </Box>

          {countdown > 0 && isActive && (
            <Text variant='webBodySecondary' marginBottom='s'>
              {t('resendCode', {
                time:
                  (countdown.toString().length == 1 ? '00:0' : '00:') +
                  countdown.toString(),
              })}
            </Text>
          )}
          {(countdown == 0 || !isActive) && (
            <TouchableOpacity
              onPress={() => {
                setVerifyCodeFinish(false);
                setFieldValue('verify_code', '');
                sendVerificationCode();
              }}>
              <Text variant='webSmall' marginBottom='s' color='greenSecondary'>
                {t('ResendCodeViaSMS')}
              </Text>
            </TouchableOpacity>
          )}
        </Box>
      </ShadowBox>
      <ConfirmModal
        showModal={errorMsg !== ''}
        isAlert={true}
        onPress={() => {
          setErrorMsg('');
        }}
        buttonText='OK'
        title={t('models:onBoarding.modal.signUpWithPhoneNumber')}
        message={errorMsg}
      />
    </Box>
  );
};

export default RegisterWithPhoneVerifycodeNew;
