import React from 'react';
import { Text, Image, StyleSheet } from 'react-native';

import { Box } from '@components/Restyle';
import theme from '@themes/theme';

const InvitedUser = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let invitationToken = urlParams.get('invitation_token') ?? '';
  let type = urlParams.get('type') ?? '';
  const id = urlParams.get('id') ?? '';

  let fromInvitationURL = true;
  const path = window.location.pathname;
  // please refer https://tasktag.atlassian.net/browse/TA-5972
  if (path.startsWith('/account_invitations/')) {
    fromInvitationURL = false;
    const code = path.substring('/account_invitations/'.length);
    invitationToken = code;
    type = 'team';
  }

  const getInvitationUrlWeb = (type: string) => {
    if (fromInvitationURL) {
      if (type) {
        return window.location.href.replace('invited-user', 'log-in');
      } else {
        return window.location.href.replace(
          'invited-user',
          'register/signup-with-email'
        );
      }
    } else {
      return window.location.href.replace(
        path,
        '/log-in?invitation_token=' + invitationToken + '&type=team'
      );
    }
  };

  const inviteUrlMobile = type
    ? 'com.eloveit.tasktag://login/' + invitationToken + `/${type}/` + id
    : process.env.ANDROID_DEEP_LINK + invitationToken;

  return (
    <Box flex={1} justifyContent='center' alignItems='center'>
      <Image
        style={styles.image}
        source={require('../../../assets/appIcon.png')}
      />

      <Box width={400}>
        <Text style={styles.text}>
          If you already have the task tag application. Please click on this
          button
        </Text>

        <Box style={styles.button}>
          <Text
            style={styles.buttonText}
            hrefAttrs={{ rel: 'noopener noreferrer', target: '_blank' }}
            href={inviteUrlMobile}>
            Open TaskTag App
          </Text>
        </Box>

        <Text style={styles.text}>
          If you don't have the task tag application. Please click on this
          button
        </Text>

        <Box style={styles.button}>
          <Text
            style={styles.buttonText}
            hrefAttrs={{ rel: 'noopener noreferrer', target: '_blank' }}
            href={getInvitationUrlWeb(type)}>
            Open TaskTag Web
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

const styles = StyleSheet.create({
  image: {
    width: 150,
    height: 150,
  },
  text: {
    margin: 30,
    fontSize: 15,
    fontWeight: '500',
    textAlign: 'center',
    marginHorizontal: 20,
  },
  button: {
    padding: 10,
    width: 200,
    borderRadius: 5,
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    marginVertical: 10,
  },
  buttonText: {
    color: theme.colors.white,
    fontWeight: '600',
  },
});

export default InvitedUser;
