import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimensions, Platform } from 'react-native';
import { ToastProvider } from 'react-native-toast-notifications';

import { CoreProviders } from '@components/CoreProviders';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import AuthStack from '@navigation/auth/auth-stack';
import { ProjectsStackParamsList } from '@navigation/projects/projects-stack';
import { TasksStackParamsList } from '@navigation/tasks/tasks-stack';

import { AppStack } from './app-stack';
import { PaymentsStackParamsList } from './payments/payments-stack';
import { ThemedNavigationContainer } from './ThemedNavigationContainer';
import { useAuthContext } from '../context/authContext';

const prefix = Linking.createURL('/');

const taskPanelConfig: LinkingOptions<ReactNavigation.RootParamList>['config'] =
  {
    screens: {
      task: {
        path: '/',
        screens: {
          'task-details': '/',
          'activity-log': '/activity-log',
          members: '/members',
          files: {
            path: '/files',
            screens: {
              'all-task-files': 'all',
              'task-media': 'media',
            },
          },
        },
      },
      'task-edit': {
        path: '/edit',
        screens: {
          'task-form': '/',
        },
      },
      'task-copy-modal': {
        path: '/duplicate',
      },
    },
  };

const taskScreensWeb = {
  tasks: {
    path: '/:taskId?',
    ...taskPanelConfig,
  },
};

const taskScreensMobile: { [Value in keyof TasksStackParamsList]: string } = {
  'task-detail': '/:taskId', // ✅
  'task-create': '/:taskId/create',
  'task-edit': '/:taskId/edit', // ✅
  'task-search': '/:taskId/search',
  'task-members': '/:taskId/members', // ✅
  'task-member-role-select-modal': '/:taskId/member-role-select-modal',
  'task-copy-modal': '/:taskId/duplicate', // ✅
  'task-activity-log': '/:taskId/activity-log', // ✅
  'task-add-members-stack': '/:taskId/add-members-stack',
  'task-details-modal': '/:taskId/details-modal',
};

const paymentsScreensWeb = {
  'pick-plans': {
    path: '/',
  },
  'create-subscription': {
    path: '/new/:planId',
  },
  'subscription-created': {
    path: '/complete/:teamId/:productId',
  },
};
const myaccountScreensWeb = {
  'subscription-created': {
    path: '/complete/:teamId/:productId',
  },
  'subscription-edit': {
    path: '/updated/:teamId/:productId',
  },
  'subscription-plans': {
    path: '/subscription-plans',
  },
};

const paymentsScreensMobile: {
  [Value in keyof PaymentsStackParamsList]: string;
} = {
  'upgrade-plus': '/',
};

const teamsScreensWeb = {
  'my-teams': {
    path: '/',
  },
  'team-details': {
    path: '/:teamId',
  },
  'team-members': {
    path: '/:teamId/members',
  },
  'team-analytics': {
    path: '/:teamId/analytics',
  },
  'team-subscription': {
    path: '/:teamId/subscription',
  },
  'add-team-members-stack': {
    path: '/:teamId/add-members',
    screens: {
      'add-members': '/',
      'edit-new-member-roles': '/roles',
    },
  },
};

const projectScreensWeb = {
  tabs: {
    path: '/',
    screens: {
      allprojects: '/allprojects',
    },
  },
  details: {
    path: '/:projectId',
    screens: {
      'project-details': '/',
      'activity-log': '/activity-log',
      tasks: {
        path: '/tasks/:taskId?',
        ...taskPanelConfig,
      },
      members: '/members',
      files: {
        path: '/files',
        screens: {
          'all-project-files': '/all',
          'project-media': '/media',
          'project-task-files': '/tasks',
        },
      },
    },
  },
  new: {
    path: 'create',
    screens: { 'create-project-form': '/' },
  },
  edit: {
    path: '/:projectId/edit',
    screens: { 'create-project-form': '/form' },
  },
  duplicate: {
    path: '/:projectId/duplicate',
    screens: { 'create-project-form': '/form' },
  },
  colorSelect: {
    path: '/:projectId/colorSelect',
    screens: { 'create-project-form': '/form' },
  },
};
const projectScreensMobile: {
  [Value in keyof ProjectsStackParamsList]: string;
} = {
  'project-details': '/:projectId',
  'project-create': '/create',
  'project-edit': '/:projectId/edit',
  'project-search': '/search',
  'project-members': '/:projectId/members',
  'project-member-role-select-modal': '/:projectId/member-role-select-modal',
  'project-copy-modal': '/:projectId/duplicate',
  'project-activity-log': '/:projectId/activity-log',
  'project-add-members-stack': '/:projectId/add-members-stack',
  'project-details-modal': '/:projectId/details-modal',
  'create-phase-stack': '/create-phase',
  'project-form-stack': '/form-stack',
  'project-tasks': '/:projectId/tasks/:taskId?',
};

const contactPanelConfig: LinkingOptions<ReactNavigation.RootParamList>['config'] =
  {
    screens: {
      contact: {
        path: '/',
        screens: {
          'contact-info': '/profile',
          'contact-projects': '/projects',
          'contact-tasks': '/tasks',
        },
      },
      'contact-task-details': {
        path: '/task',
      },
    },
  };

const contactScreensWeb = {
  contacts: {
    path: '/:userId?',
    ...contactPanelConfig,
  },
};

const appConfig: LinkingOptions<ReactNavigation.RootParamList>['config'] = {
  screens: {
    'password-reset': 'users/password/edit',
    'log-in': 'login/:token/:type?/:id',
    register: Platform.OS === 'web' ? 'register' : 'register/:token',
    'chats-stack': {
      screens: {
        'chat-detail': 'chat/:id/:messageCursor?',
      },
    },
    home: {
      path: '/',
      screens: {
        'chats-home': 'chat',
        ...(Platform.OS !== 'web' && {
          'projects-home': 'projects/all-projects',
        }),
        ...(Platform.OS !== 'web' && { 'tasks-home': 'task-list' }),
      },
    },
    'projects-stack': {
      path: 'projects',
      screens: Platform.OS === 'web' ? projectScreensWeb : projectScreensMobile,
    },
    'tasks-stack': {
      path: 'tasks',
      screens: Platform.OS === 'web' ? taskScreensWeb : taskScreensMobile,
    },
    'contacts-stack': {
      path: 'contacts',
      screens: contactScreensWeb,
    },
    'payments-stack': {
      path: 'payments',
      screens:
        Platform.OS === 'web' ? paymentsScreensWeb : paymentsScreensMobile,
    },
    'my-account': {
      path: 'my-account',
      screens: myaccountScreensWeb,
    },
    'teams-stack': {
      path: 'teams',
      screens: teamsScreensWeb,
    },
  },
};

const linking: LinkingOptions<ReactNavigation.RootParamList> = {
  prefixes: [
    prefix,
    'com.eloveit.tasktag:///',
    'tasktag:///',
    'https://beta.app.tasktag.com/',
    'https://app.tasktag.com/',
  ],
  config: appConfig,
};

// switch between the <AppStack /> and an <AuthStack /> based on user's auth

export const AppNavigator: React.FC = () => {
  const { token, loading } = useAuthContext();
  const { t } = useTranslation('shared');
  if (Platform.OS === 'web') {
    const path = window.location.pathname;
    if (path.startsWith('/account_invitations/')) {
      const code = path.substring('/account_invitations/'.length);
      window.sessionStorage.setItem('invitation_token', code);
      // this from when user signup -> create team -> invite user ->
      // invited user click invitation link from email inbox, so I put it here -- april TA-5972
      window.sessionStorage.setItem('type', 'team');
    } else {
      const queryParameters = new URLSearchParams(window.location.search);
      const invitationToken = queryParameters.get('invitation_token') ?? '';
      const type = queryParameters.get('type') ?? '';
      if (invitationToken) {
        window.sessionStorage.setItem('invitation_token', invitationToken);
        window.sessionStorage.setItem('type', type);
      }
    }
  }

  const getStack = () => {
    if (loading) {
      return (
        <ActivityIndicatorLoading
          top={(Dimensions.get('window').height - 40) / 2}
        />
      );
    } else {
      return (
        <CoreProviders>
          <ToastProvider>{token ? <AppStack /> : <AuthStack />}</ToastProvider>
        </CoreProviders>
      );
    }
  };

  return (
    <ThemedNavigationContainer
      linking={linking}
      documentTitle={{ enabled: true, formatter: () => t('appName') }}>
      {getStack()}
    </ThemedNavigationContainer>
  );
};
