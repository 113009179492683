import { useIsFocused } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Animated, RefreshControl } from 'react-native';
import { InferType } from 'yup';

import ChatCard from '@components/Chat/ChatCard.web';
import { Scrollbar } from '@components/Hoverable/Scrollbar';
import { ChatModal } from '@components/Modals/ChatModal.web';
import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { Box } from '@components/Restyle';
import {
  Chat,
  useArchiveChatMutation,
  useDeleteGroupChatMutation,
  useLeaveChatMutation,
  useUpdateChatMutation,
} from '@graphql/generated';
import useChatInput from '@hooks/useChatInput';
import useFileProcessor from '@hooks/useFileProcessor';
import useMe from '@hooks/useMe';
import { groupChatSettingsSchema } from '@schemas/groupChatSettingsSchema';
import { ChatRelatedTags } from '@screens/Chats/ChatRelatedTags.web';
import { GroupChatMembers } from '@screens/Chats/GroupChatMembers.web';
import { GroupChatSettingsProfile } from '@screens/Chats/GroupChatSettingsProfile.web';
import { useFetchContentTypes } from '@utils/fetchContentTypes';


export type GroupChatSettingsValues = InferType<typeof groupChatSettingsSchema>;

type ChatListProps = {
  chats: Chat[];
  onPress?: (chat: Chat) => void;
  onPinPress?: (chat: Chat) => void;
  onMutePress?: (chat: Chat) => void;
  isEditing: boolean;
  selected?: Chat[];
  loading: boolean;
  refreshing: boolean;
  onRefresh: () => void;
  hideSeparatorItem?: boolean;
  filterVal?: string;
  open: boolean;
  hasNextPage: boolean;
  loadMore: () => void;
  fetchChats: () => void;
};

export const ChatList: React.FC<ChatListProps> = ({
  chats,
  onPress,
  isEditing,
  selected,
  loading,
  refreshing = false,
  onRefresh,
  hideSeparatorItem,
  filterVal,
  open,
  hasNextPage,
  loadMore,
  fetchChats,
}) => {
  const { me } = useMe();
  const ref_flashlist = useRef<FlashList<Chat>>(null);
  const { t } = useTranslation();
  const [xPos, setXPos] = useState(0);
  const [yPos, setYPos] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const fadeAnim = useState(new Animated.Value(0))[0];
  const mainRef = useRef<HTMLElement>(null);
  const [bodyWidth, setBodyWidth] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);
  const { selectedChat: chat, setSelectedChat } = useChatInput();
  const { name, description, avatar, settings } = chat || {};
  const initialValues: GroupChatSettingsValues = {
    name: name || '',
    description: description || '',
    avatar: avatar || null,
  };
  const isFocused = useIsFocused();

  const [mute, setMute] = useState(!!settings?.mute);
  const [pin, setPin] = useState(!!settings?.pin);
  useEffect(() => {
    setPin(!!settings?.pin);
    setMute(!!settings?.mute);
  }, [settings]);
  const [updateChat, { loading: loadingUpdateChat }] = useUpdateChatMutation();

  const { processFiles } = useFileProcessor();
  const { fetchAllowedContentTypes } = useFetchContentTypes();

  const [isShowEdit, setIsShowEdit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showLeaveChatModal, setShowLeaveChatModal] = useState(false);
  const [isShowChatRelatedTags, setIsShowChatRelatedTags] = useState(false);
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  const [leaveChat] = useLeaveChatMutation({
    onCompleted: () => {
      setShowLeaveChatModal(false);
    },
    refetchQueries: ['listChats'],
  });

  const [archiveChat] = useArchiveChatMutation({
    onCompleted: () => {
      setShowArchiveModal(false);
    },
    refetchQueries: ['listChats'],
  });

  const [deleteGroup] = useDeleteGroupChatMutation({
    onCompleted: () => {
      setShowDeleteGroupModal(false);
    },
    refetchQueries: ['listChats'],
  });

  const handleLeaveChat = () => {
    chat?.id &&
      leaveChat({
        variables: {
          id: chat?.id,
        },
      });
  };

  const handleArchiveChat = () => {
    chat?.id &&
      archiveChat({
        variables: {
          id: chat?.id,
        },
      });
  };

  const handleDeleteGroup = () => {
    deleteGroup({
      variables: {
        id: chat?.id,
      },
    });
  };

  const handlePinToggle = (newPin: boolean | undefined) => {
    if (newPin === undefined) {
      return;
    }

    if (!loadingUpdateChat && chat?.id) {
      setPin(newPin);
      updateChat({
        variables: {
          id: chat.id,
          attributes: {
            pin: newPin,
          },
        },
      }).then(() => {
        onRefresh();
      });
    }
  };

  const handleMuteToggle = (newMute: boolean | undefined) => {
    if (newMute === undefined) {
      return;
    }
    if (!loadingUpdateChat && chat?.id) {
      setMute(newMute);
      updateChat({
        variables: {
          id: chat.id,
          attributes: {
            mute: newMute,
          },
        },
      }).then(() => {
        onRefresh();
      });
    }
  };

  const saveChanges = (values: GroupChatSettingsValues) => {
    const newChatName = values.name ? values.name : name;
    const newAvatar = values.avatar !== avatar ? values.avatar : undefined;
    const newChatDescription = values.description
      ? values.description
      : description;

    if (values.avatar && me && newAvatar) {
      const {
        name: avatarName,
        clientId,
        contentType,
        isImage,
        size,
        isAudio,
        duration,
        isPreviewable,
        thumbnail,
      } = values.avatar;

      const doc: Document = {
        __typename: 'Document',
        id: 'chat_avatar',
        owner: me,
        name: avatarName,
        clientId,
        contentType: contentType,
        isImage,
        createdAt: new Date().toISOString(),
        size,
        file: {
          __typename: 'File',
          cdnBaseUrl: '',
          path: '',
          id: clientId,
          url: '',
          ...values.avatar,
          isPreviewable: isPreviewable,
          thumbnail: thumbnail,
        },
        isAudio,
        isPreviewable,
        thumbnail,
        duration,
      };
      const documents = [doc];
      processFiles(documents)
        .then((response) => {
          const { blobId } = response[0];
          if (!loadingUpdateChat)
            updateChat({
              variables: {
                id: chat?.id || '',
                attributes: {
                  name: newChatName,
                  description: newChatDescription,
                  avatarBlobId: blobId,
                },
              },
            }).then(() => {
              onRefresh();
            });
        })
        .catch((_err) => {
          setSubmitting(false);
        });
    } else {
      updateChat({
        variables: {
          id: chat?.id || '',
          attributes: {
            name: newChatName || '',
            description: newChatDescription,
          },
        },
      }).then(() => {
        onRefresh();
      });
    }
    setIsShowEdit(false);
    setSubmitting(true);
  };

  const handleClick = () => {
    setTimeout(() => {
      setShowMenu(false);
    }, 100);
  };

  const handleContextMenu = (e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    const element = mainRef.current;
    if (element) {
      element.addEventListener('click', handleClick);
      element.addEventListener('contextmenu', handleContextMenu);
      return () => {
        element.removeEventListener('click', handleClick);
        element.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, []);

  useEffect(() => {
    if (isFocused) {
      fetchChats();
    }
  }, [isFocused]);

  useEffect(() => {
    fetchAllowedContentTypes();
  }, []);

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: showMenu ? 1 : 0,
      duration: 100,
      useNativeDriver: false,
    }).start();
  }, [showMenu, fadeAnim]);

  const getMenuListLength = (chat: Chat) => {
    const { owner, settings, leaveOn } = chat || {};

    const ownerId = owner?.id;
    const isOwner = me?.id === ownerId;
    const isArchived = settings?.archivedAt;
    const isGroupChat = chat?.isGroupChat;

    let i = 0;
    if (isGroupChat && !leaveOn) i = i + 1; // Edit

    if (isGroupChat) i = i + 1; //Chat Members

    i = i + 1; //Chat Related Tags

    if (!leaveOn) i = i + 2; //Mute and Pin Chat

    if (isGroupChat && !isArchived && isOwner) i = i + 1; //Archive For Everyone

    if (!isGroupChat && !isArchived) i = i + 1; //Archive Chat

    i = i + 1; //Clear Chat History

    if (isGroupChat && !isOwner && !isArchived && !leaveOn) i = i + 1; //Leave Chat

    if (isGroupChat && leaveOn) i = i + 1; //Delete Group Chat

    return i;
  };

  const onRightClick = useCallback(
    (chat: Chat, mouseX: number, mouseY: number) => {
      setShowMenu(false);
      setSelectedChat(chat);
      const menuListLength = getMenuListLength(chat);
      if (bodyHeight - mouseY < 240) {
        mouseY = mouseY - (menuListLength + 1) * 40 - 12;
      }
      const element = mainRef.current;
      if (element) {
        const { left, top } = element.getBoundingClientRect();
        mouseX = mouseX - left;
        mouseY = mouseY - top;
      }
      if (bodyWidth - mouseX < 270) {
        mouseX = mouseX - 281;
      }
      setXPos(mouseX);
      setYPos(mouseY);
      setTimeout(() => {
        setShowMenu(true);
      }, 100);
    },
    [bodyWidth, bodyHeight]
  );

  const onRightPinPress = (chat: Chat) => {
    const { id, settings } = chat;

    updateChat({
      variables: {
        id,
        attributes: {
          pin: !settings?.pin,
        },
      },
      optimisticResponse: {
        updateChat: {
          ...chat,
          settings: {
            ...settings,
            pin: !settings?.pin,
            mute: !!settings?.mute,
          },
        },
      },
    }).then(() => {
      onRefresh();
    });
  };

  const onRightMutePress = (chat: Chat) => {
    const { id, settings } = chat;

    updateChat({
      variables: {
        id,
        attributes: {
          mute: !settings?.mute,
        },
      },
      optimisticResponse: {
        updateChat: {
          ...chat,
          settings: {
            ...settings,
            mute: !settings?.mute,
            pin: !!settings?.pin,
          },
        },
      },
    }).then(() => {
      onRefresh();
    });
  };

  const renderFlashList = (
    showsVerticalScrollIndicator: boolean,
    setContentOffset?: (offset: { x: number; y: number }) => void,
    setContentSize?: (size: number) => void
  ) => {
    return (
      <FlashList
        extraData={[selected, isEditing]}
        ref={ref_flashlist}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        data={chats}
        renderItem={({ item, index }) => (
          <Box accessibilityLabel={isEditing ? 'Edit chat item' : 'Chat item'}>
            <ChatCard
              filterVal={filterVal}
              chatCard={item}
              onMutePress={onRightMutePress}
              onPinPress={onRightPinPress}
              onPress={onPress}
              isEditing={isEditing}
              isSelected={selected?.some((i) => i.id === item.id)}
              collapsed={!open}
              onRightClick={onRightClick}
              contentHeightLimit={true}
            />
            {!open &&
              item?.settings?.pin &&
              (!chats[index + 1] || !chats[index + 1].settings?.pin) && (
                <Box
                  borderBottomWidth={1}
                  borderBottomColor='grey02'
                  marginHorizontal='s'
                  style={{ marginTop: -1 }}
                />
              )}
          </Box>
        )}
        estimatedItemSize={chats?.length > 0 ? 200 * chats?.length : 200}
        keyExtractor={(item: Chat, index: number) => item.id + index}
        ItemSeparatorComponent={
          hideSeparatorItem
            ? undefined
            : () => (
                <Box
                  borderBottomWidth={1}
                  borderBottomColor='grey01'
                  marginHorizontal='m'
                />
              )
        }
        refreshing={loading}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        ListFooterComponent={() => <Box paddingBottom='listFooter' />}
        onContentSizeChange={(_, height) =>
          setContentSize && setContentSize(height)
        }
        onScroll={({ nativeEvent: { contentOffset } }) =>
          setContentOffset && setContentOffset(contentOffset)
        }
        onEndReached={hasNextPage ? loadMore : undefined}
      />
    );
  };

  return (
    <Box
      flex={1}
      ref={mainRef}
      onLayout={(e: any) => {
        if (e.nativeEvent.layout.width > 0) {
          setBodyWidth(e.nativeEvent.layout.width);
          setBodyHeight(e.nativeEvent.layout.height);
        }
      }}>
      <Scrollbar
        HoverableProps={{
          style: {
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center',
            display: 'flex',
          },
        }}>
        {({ setContentOffset, setContentSize, scrollTo }) => {
          scrollTo !== undefined &&
            ref_flashlist.current?.scrollToOffset({ offset: scrollTo });
          return renderFlashList(false, setContentOffset, setContentSize);
        }}
      </Scrollbar>
      {showMenu && (
        <Animated.View
          style={{
            width: 281,
            position: 'absolute',
            opacity: fadeAnim,
            top: yPos,
            left: xPos,
          }}>
          <ChatModal
            onDismiss={() => {
              setShowMenu(false);
            }}
            onEditPress={() => {
              setIsShowEdit(true);
            }}
            onChatMembersPress={() => {
              setShowAddMembersModal(true);
            }}
            onRelatedTagsPress={() => {
              setIsShowChatRelatedTags(true);
            }}
            onMuteChatPress={() => {
              handleMuteToggle(!mute);
            }}
            onPinChatPress={() => {
              handlePinToggle(!pin);
            }}
            onArchiveChatPress={() => {
              setShowArchiveModal(true);
            }}
            onLeaveChatPress={() => {
              setShowLeaveChatModal(true);
            }}
            onDeleteChatPress={() => {
              setShowDeleteGroupModal(true);
            }}
          />
        </Animated.View>
      )}
      {isShowEdit && (
        <Formik<GroupChatSettingsValues>
          validateOnMount={true}
          initialValues={initialValues}
          onSubmit={saveChanges}
          validationSchema={groupChatSettingsSchema}>
          <GroupChatSettingsProfile
            chat={chat}
            loading={submitting}
            onClose={() => setIsShowEdit(false)}
          />
        </Formik>
      )}
      {isShowChatRelatedTags && (
        <ChatRelatedTags
          chatId={chat?.id}
          onClose={() => setIsShowChatRelatedTags(false)}
        />
      )}

      {showAddMembersModal && chat?.id && (
        <GroupChatMembers
          chatId={chat.id}
          showAddMembersModal={showAddMembersModal}
          onClose={() => setShowAddMembersModal(false)}
        />
      )}
      {showArchiveModal && (
        <ConfirmModal
          showModal={showArchiveModal}
          onClose={() => setShowArchiveModal(false)}
          onPress={handleArchiveChat}
          buttonText={t('shared:archiveChat')}
          title={t('shared:archive')}
          message={t('models:chat.archive.message')}
        />
      )}
      {showLeaveChatModal && (
        <ConfirmModal
          showModal={showLeaveChatModal}
          onClose={() => setShowLeaveChatModal(false)}
          onPress={handleLeaveChat}
          buttonText={t('models:chat.leaveGroup.cta')}
          title={t('models:chat.leaveGroup.cta')}
          message={t('models:chat.leaveGroup.message')}
        />
      )}
      {showDeleteGroupModal && (
        <ConfirmModal
          showModal={showDeleteGroupModal}
          onClose={() => setShowDeleteGroupModal(false)}
          onPress={handleDeleteGroup}
          buttonText={t('models:chat.deleteGroup.cta')}
          title={t('models:chat.deleteGroup.cta')}
          message={t('models:chat.deleteGroup.message')}
        />
      )}
    </Box>
  );
};
