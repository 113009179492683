import { ApolloError } from '@apollo/client';
import { Platform } from 'react-native';
import { useToast } from 'react-native-toast-notifications';

import { AsyncStorage } from '@utils/storage';

const useInvitationHandlers = () => {
  const toast = useToast();

  const handleClearTeamInvitationToken = async () => {
    toast.show('You have successfully joined the team', {
      placement: 'center',
      duration: 3000,
    });
    if (Platform.OS === 'web') {
      window.sessionStorage.setItem('invitation_token', '');
    } else {
      await AsyncStorage.setItem('invitation_token', '');
    }
  };

  const handleOnErrorInvitation = async (error: ApolloError) => {
    toast.show(error.toString(), {
      placement: 'center',
      duration: 3000,
    });

    if (Platform.OS === 'web') {
      window.sessionStorage.setItem('invitation_token', '');
    } else {
      await AsyncStorage.setItem('invitation_token', '').then(() => {});
    }
  };

  return {
    handleClearTeamInvitationToken,
    handleOnErrorInvitation,
  };
};

export default useInvitationHandlers;
