import {
  Inter_400Regular,
  Inter_500Medium,
  Inter_600SemiBold,
  useFonts,
} from '@expo-google-fonts/inter';
import { ThemeProvider } from '@shopify/restyle';
import React from 'react';
import { Helmet } from 'react-helmet';
import { View } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
// eslint-disable-next-line import/order
import { SafeAreaProvider } from 'react-native-safe-area-context';

import './src/i18n/i18n.config.ts';

import { AuthProvider } from './src/context/authContext';
import { ApolloClientProvider } from './src/hooks/useApolloClient';
import { AppNavigator } from './src/navigation/app-navigator';
import theme from './themes/theme';

export default function App() {
  const [fontsLoaded] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
  });

  // app is loading
  if (!fontsLoaded) {
    return <View />;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const invitationToken = urlParams.get('invitation_token') ?? '';
  const type = urlParams.get('type') ?? '';
  const id = urlParams.get('id') ?? '';

  if (invitationToken || type || id) {
    window.sessionStorage.setItem('invitation_token', invitationToken);
    window.sessionStorage.setItem('link_type', type);
    window.sessionStorage.setItem('task_or_project_id', id);
  } else {
    const path = window.location.pathname;
    if (path.startsWith('/account_invitations/')) {
      const code = path.substring('/account_invitations/'.length);
      window.sessionStorage.setItem('invitation_token', code);
      window.sessionStorage.setItem('link_type', 'team');
    }
  }

  return (
    <>
      <Helmet>
        <meta name='apple-itunes-app' content='app-id=1635406641' />
      </Helmet>
      <GestureHandlerRootView style={{ flex: 1 }}>
        <ApolloClientProvider>
          <AuthProvider>
            <SafeAreaProvider>
              <ThemeProvider theme={theme}>
                <AppNavigator />
              </ThemeProvider>
            </SafeAreaProvider>
          </AuthProvider>
        </ApolloClientProvider>
      </GestureHandlerRootView>
    </>
  );
}
