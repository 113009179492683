import { Formik } from 'formik';
import React, { memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InferType } from 'yup';

import { ConfirmModal } from '@components/Modals/ConfirmModal';
import { EmojiModal } from '@components/Modals/EmojiModal.web';
import TaskModal from '@components/Modals/TaskModal.web';
import { Box } from '@components/Restyle';
import { MenuItem } from '@components/shared/Popup/PopupItem';
import { SearchInputWithTags } from '@components/shared/SearchInput/SearchInputWithTags.web';
import { Drawer } from '@components/Web/Drawer';
import {
  ChatDrawerScreen,
  useNavigateToChatDrawer,
  useWebDrawer,
} from '@components/Web/Drawer/WebDrawerContext';
import {
  Document,
  useUpdateChatMutation,
  useArchiveChatMutation,
  useLeaveChatMutation,
  useDeleteGroupChatMutation,
  useGetChatQuery,
} from '@graphql/generated';
import useActiveChat from '@hooks/useActiveChat';
import useChatAvatar from '@hooks/useChatAvatar';
import { ChatInputProvider } from '@hooks/useChatInput';
import useEmoji from '@hooks/useEmoji';
import useFileProcessor from '@hooks/useFileProcessor';
import useMe from '@hooks/useMe';
import useSearch from '@hooks/useSearch';
import { groupChatSettingsSchema } from '@schemas/groupChatSettingsSchema';
import ChatMessageData from '@screens/Chats/ChatMessageData';
import { ChatRelatedTags } from '@screens/Chats/ChatRelatedTags.web';
import { CreateGroupChat } from '@screens/Chats/CreateGroupChat.web';
import { GroupChatMembers } from '@screens/Chats/GroupChatMembers.web';
import { GroupChatSettingsProfile } from '@screens/Chats/GroupChatSettingsProfile';
import NewChat from '@screens/Chats/NewChat';
import theme from '@themes/theme';

export type GroupChatSettingsValues = InferType<typeof groupChatSettingsSchema>;

export const ChatDrawer = memo(() => {
  const {
    activeChatId,
    activeChat,
    isEditTagModalOpen,
    isForwardMessageModalOpen,
    setIsOpenChat,
    setActiveChat,
  } = useActiveChat();
  const { setIsSearching, isSearching } = useSearch();
  const {
    screen: currentScreen,
    chatExpanded,
    setChatExpanded,
    toggleChatExpanded,
    setChatDrawerWidth,
    shouldChatListOpen,
    setShouldChatListOpen,
    setChatListOpen,
  } = useWebDrawer();
  const navigation = useNavigateToChatDrawer();

  const [isShowEdit, setIsShowEdit] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const { processFiles } = useFileProcessor();
  const { id: chatId } = activeChat || { id: activeChatId };

  const { data: chatData } = useGetChatQuery({
    fetchPolicy: 'no-cache',
    variables: { id: chatId as string },
    skip: !chatId,
  });
  const { getChat: chat } = chatData || {};
  const { name, description, avatar, owner, settings, leaveOn } = chat || {};

  const initialValues: GroupChatSettingsValues = {
    name: name || '',
    description: description || '',
    avatar: avatar || null,
  };
  const { t } = useTranslation();
  const { getChatAvatar, getChatAvatarLabel } = useChatAvatar();
  const { me } = useMe();

  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const [showLeaveChatModal, setShowLeaveChatModal] = useState(false);
  const [isShowChatRelatedTags, setIsShowChatRelatedTags] = useState(false);
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);

  const {
    setEmojiValue,
    isEmojiPickerOpened,
    toggleEmojiPickerOpened,
    setShowMessageHighlightModal,
  } = useEmoji();

  const [mute, setMute] = useState(!!settings?.mute);
  const [pin, setPin] = useState(!!settings?.pin);
  const [updateChat, { loading: loadingUpdateChat }] = useUpdateChatMutation();

  const ownerId = owner?.id;
  const isOwner = me?.id === ownerId;
  const isArchived = settings?.archivedAt;
  const isGroupChat = chat?.isGroupChat;

  useEffect(() => {
    if (settings) {
      setMute(settings.mute);
      setPin(settings.pin);
    } else {
      setMute(false);
      setPin(false);
    }
  }, [settings]);

  const [leaveChat] = useLeaveChatMutation({
    onCompleted: () => {
      updateChat({
        variables: {
          id: chat.id,
        },
      });
      setShowLeaveChatModal(false);
      handleDrawerOnclose();
      navigation({ screen: ChatDrawerScreen.closed });
    },
    refetchQueries: ['listChats'],
  });

  const [deleteGroup] = useDeleteGroupChatMutation({
    onCompleted: () => {
      updateChat({
        variables: {
          id: chat.id,
        },
      });
      setShowDeleteGroupModal(false);
      handleDrawerOnclose();
      navigation({ screen: ChatDrawerScreen.closed });
    },
    refetchQueries: ['listChats'],
  });

  const [archiveChat] = useArchiveChatMutation({
    onCompleted: () => {
      updateChat({
        variables: {
          id: chat.id,
        },
      });
      setShowArchiveModal(false);
      handleDrawerOnclose();
      navigation({ screen: ChatDrawerScreen.closed });
    },
    refetchQueries: ['listChats'],
  });

  const handleLeaveChat = () => {
    leaveChat({
      variables: {
        id: chat.id,
      },
    });
  };

  const handleArchiveChat = () => {
    archiveChat({
      variables: {
        id: chat.id,
      },
    });
  };

  const handleDeleteGroup = () => {
    deleteGroup({
      variables: {
        id: chat.id,
      },
    });
  };

  const handleSearchCancelPress = () => {
    setIsSearching(false);
  };

  const handleDrawerOnclose = () => {
    if (isSearching) {
      setIsSearching(false);
    } else {
      setIsOpenChat(false);
      setActiveChat(undefined);
      setChatExpanded(false);
      if (shouldChatListOpen) {
        setChatListOpen(true);
      }
      setShouldChatListOpen(false);
      navigation({ screen: ChatDrawerScreen.closed });
    }
  };

  const handlePinToggle = (newPin: boolean | undefined) => {
    if (newPin === undefined) {
      return;
    }

    if (!loadingUpdateChat && chat?.id) {
      setPin(newPin);
      updateChat({
        variables: {
          id: chat.id,
          attributes: {
            pin: newPin,
          },
        },
      });
    }
  };

  const handleMuteToggle = (newMute: boolean | undefined) => {
    if (newMute === undefined) {
      return;
    }
    if (!loadingUpdateChat && chat?.id) {
      setMute(newMute);
      updateChat({
        variables: {
          id: chat.id,
          attributes: {
            mute: newMute,
          },
        },
      });
    }
  };

  const getMenuList: MenuItem[] = [
    ...(isGroupChat
      ? [
          !leaveOn && {
            name: t('models:chat.chatMenuList.edit'),
            iconName: 'Edit',
            onSelect: () => {
              setIsShowEdit(true);
            },
            numberOfLines: 1,
          },
          {
            name: t('models:chat.chatMenuList.chatMembers'),
            iconName: 'Users',
            onSelect: () => {
              setShowAddMembersModal(true);
            },
            numberOfLines: 1,
          },
        ]
      : []),
    {
      name: 'Search',
      iconName: 'Search',
      onSelect: () => {
        setIsSearching(true);
      },
      numberOfLines: 1,
    },
    {
      name: t('models:chat.chatMenuList.relatedTags'),
      iconName: 'Hash',
      onSelect: () => {
        setIsShowChatRelatedTags(true);
      },
      numberOfLines: 1,
    },
    ...(!leaveOn
      ? [
          {
            name: mute
              ? t('models:chat.chatMenuList.unmuteChat')
              : t('models:chat.chatMenuList.muteChat'),
            iconName: 'VolumeX',
            numberOfLines: 1,
            onSelect: () => {
              handleMuteToggle(!mute);
            },
          },
          {
            name: pin
              ? t('models:chat.chatMenuList.unpinChat')
              : t('models:chat.chatMenuList.pinChat'),
            iconName: 'Pin2',
            numberOfLines: 1,
            onSelect: () => {
              handlePinToggle(!pin);
            },
          },
        ]
      : []),

    ...(isGroupChat && !isArchived && isOwner
      ? [
          {
            name: t('models:chat.chatMenuList.archiveForEveryone'),
            iconName: 'Archive',
            numberOfLines: 1,
            isRed: true,
            onSelect: () => setShowArchiveModal(true),
          },
        ]
      : []),

    ...(!isGroupChat && !isArchived
      ? [
          {
            name: t('models:chat.chatMenuList.archiveChatHistory'),
            iconName: 'Archive',
            numberOfLines: 1,
            onSelect: () => setShowArchiveModal(true),
          },
        ]
      : []),
    {
      name: t('models:chat.chatMenuList.clearChatHistory'),
      iconName: 'Chat',
      isComingSoon: true,
      numberOfLines: 1,
    },
    ...(isGroupChat && !isOwner && !isArchived && !leaveOn
      ? [
          {
            name: t('models:chat.chatMenuList.leaveChat'),
            iconName: 'Leave',
            numberOfLines: 1,
            isRed: true,
            onSelect: () => {
              setShowLeaveChatModal(true);
            },
          },
        ]
      : []),
    ...(isGroupChat && leaveOn
      ? [
          {
            name: t('models:chat.deleteGroup.cta'),
            iconName: 'LogOut',
            numberOfLines: 1,
            isRed: true,
            onSelect: () => {
              setShowDeleteGroupModal(true);
            },
          },
        ]
      : []),
  ];

  const Screen = useMemo(
    () =>
      ({
        screen,
        lazy,
        children,
      }: {
        screen: ChatDrawerScreen[] | ChatDrawerScreen;
        lazy?: true;
        children: ReactNode;
      }) => {
        const visible =
          (Array.isArray(screen) && screen.includes(currentScreen)) ||
          screen === currentScreen;
        return (
          <Box visible={visible} flex={1}>
            {(!lazy || visible) && children}
          </Box>
        );
      },
    [currentScreen]
  );

  const saveChanges = (values: GroupChatSettingsValues) => {
    const newChatName = values.name ? values.name : name;
    const newAvatar = values.avatar !== avatar ? values.avatar : undefined;
    const newChatDescription = values.description
      ? values.description
      : description;

    if (values.avatar && me && newAvatar) {
      const {
        name: avatarName,
        clientId,
        contentType,
        isImage,
        size,
        isPreviewable,
        thumbnail,
        isAudio,
        duration,
      } = values.avatar;

      const doc: Document = {
        __typename: 'Document',
        id: 'chat_avatar',
        owner: me,
        name: avatarName,
        clientId,
        contentType: contentType,
        isImage,
        createdAt: new Date().toISOString(),
        size,
        file: {
          __typename: 'File',
          cdnBaseUrl: '',
          path: '',
          id: clientId,
          url: '',
          ...values.avatar,
        },
        isAudio,
        isPreviewable: isPreviewable,
        thumbnail: thumbnail,
        duration,
      };
      const documents = [doc];
      processFiles(documents)
        .then((response) => {
          const { blobId } = response[0];
          if (!loadingUpdateChat)
            updateChat({
              variables: {
                id: chatId || '',
                attributes: {
                  name: newChatName,
                  description: newChatDescription,
                  avatarBlobId: blobId,
                },
              },
              onCompleted: () => {
                setActiveChat({
                  ...chat,
                  name: newChatName || '',
                  avatar: newAvatar,
                  description: newChatDescription,
                  avatarBlobId: blobId,
                });
              },
            });
        })
        .catch((_err) => {
          setSubmitting(false);
        });
    } else {
      updateChat({
        variables: {
          id: chatId || '',
          attributes: {
            name: newChatName || '',
            description: newChatDescription,
          },
        },
        onCompleted: () => {
          setActiveChat({
            ...chat,
            name: newChatName,
            description: newChatDescription,
          });
        },
      });
    }
    setIsShowEdit(false);
    setSubmitting(true);
  };

  const getSubtitle = () => {
    if (isArchived) return t('shared:archived');
    return chat?.isGroupChat ? `${chat.users.length} members` : '';
  };

  const getTitle = () => {
    if (isSearching) return 'Chat history search';
    return name ? name : getChatAvatarLabel();
  };
  return (
    <Box
      height='100vh'
      onLayout={(e) => setChatDrawerWidth(e.nativeEvent.layout.width)}
      visible={currentScreen !== ChatDrawerScreen.closed}
      flex={1}>
      <ChatInputProvider>
        <Box
          flex={1}
          height='100vh'
          maxHeight='100vh'
          flexDirection='column'
          justifyContent='flex-end'>
          {isEmojiPickerOpened && (
            <EmojiModal
              onEmoji={(emoji) => {
                setEmojiValue(emoji);
                toggleEmojiPickerOpened(false);
                setShowMessageHighlightModal(false);
              }}
            />
          )}
          <Box width='100%' height='100%'>
            <Screen
              screen={[
                ChatDrawerScreen.details,
                ChatDrawerScreen.completeTaskModal,
                ChatDrawerScreen.assignTaskModal,
              ]}>
              <Drawer
                chatId={chat?.id}
                showHeader={!isSearching}
                isGroupChat={isGroupChat}
                avatar={avatar ? avatar : getChatAvatar() || undefined}
                title={getTitle()}
                titleVariant={
                  isSearching ? 'heading2' : 'labelEmphasizedChatDetialTitle'
                }
                subtitle={getSubtitle()}
                subtitleVariant='bodySecondary'
                onClose={handleDrawerOnclose}
                chatExpanded={chatExpanded}
                onExpandCollapse={toggleChatExpanded}
                elevated
                elevatedContent={currentScreen !== ChatDrawerScreen.details}
                showAvatar={!isSearching}
                menuList={isArchived || isSearching ? [] : getMenuList}>
                <Screen screen={ChatDrawerScreen.details}>
                  <Box flex={1} flexDirection='column'>
                    {!isEditTagModalOpen &&
                      !isForwardMessageModalOpen &&
                      isSearching && (
                        <Box mx='s'>
                          <SearchInputWithTags
                            autofocus
                            onCancelPress={handleSearchCancelPress}
                            isFromSearchHistory={true}
                            dismissOutclick={true}
                          />
                        </Box>
                      )}

                    <Box
                      flex={1}
                      borderTopColor='grey02'
                      borderTopWidth={1}
                      style={{
                        backgroundColor: theme.colors.greenSecondaryMild + '27',
                      }}>
                      <ChatMessageData />
                    </Box>
                  </Box>
                </Screen>
                <Screen screen={ChatDrawerScreen.completeTaskModal} lazy>
                  <Box
                    flex={1}
                    borderTopColor='grey02'
                    borderTopWidth={1}
                    flexDirection='column'>
                    <TaskModal complete />
                  </Box>
                </Screen>
                <Screen screen={ChatDrawerScreen.assignTaskModal} lazy>
                  <Box
                    flex={1}
                    borderTopColor='grey02'
                    borderTopWidth={1}
                    flexDirection='column'>
                    <TaskModal />
                  </Box>
                </Screen>
              </Drawer>
            </Screen>
            <Screen screen={ChatDrawerScreen.newChat}>
              <Drawer
                title={t('models:chat.labels.newChat')}
                onClose={() => navigation({ screen: ChatDrawerScreen.closed })}
                elevated>
                <NewChat />
              </Drawer>
            </Screen>
            <Screen screen={ChatDrawerScreen.newGroupChat}>
              <Drawer
                title={t('models:chat.labels.newChat')}
                onClose={() => navigation({ screen: ChatDrawerScreen.closed })}
                elevated
                elevatedContent>
                <CreateGroupChat />
              </Drawer>
            </Screen>
            {isShowEdit && (
              <Formik<GroupChatSettingsValues>
                validateOnMount={true}
                initialValues={initialValues}
                onSubmit={saveChanges}
                validationSchema={groupChatSettingsSchema}>
                <GroupChatSettingsProfile
                  chat={chat}
                  loading={submitting}
                  onClose={() => setIsShowEdit(false)}
                />
              </Formik>
            )}

            {isShowChatRelatedTags && (
              <ChatRelatedTags
                chatId={chat?.id}
                onClose={() => setIsShowChatRelatedTags(false)}
              />
            )}

            {showAddMembersModal && chat?.id && (
              <GroupChatMembers
                chatId={chat.id}
                showAddMembersModal={showAddMembersModal}
                onClose={() => setShowAddMembersModal(false)}
              />
            )}
          </Box>
        </Box>
        {showArchiveModal && (
          <ConfirmModal
            showModal={showArchiveModal}
            onClose={() => setShowArchiveModal(false)}
            onPress={handleArchiveChat}
            buttonText={t('shared:archiveChat')}
            title={t('shared:archive')}
            message={t('models:chat.archive.message')}
          />
        )}
        {showLeaveChatModal && (
          <ConfirmModal
            showModal={showLeaveChatModal}
            onClose={() => setShowLeaveChatModal(false)}
            onPress={handleLeaveChat}
            buttonText={t('models:chat.leaveGroup.cta')}
            title={t('models:chat.leaveGroup.cta')}
            message={t('models:chat.leaveGroup.message')}
          />
        )}
        {showDeleteGroupModal && (
          <ConfirmModal
            showModal={showDeleteGroupModal}
            onClose={() => setShowDeleteGroupModal(false)}
            onPress={handleDeleteGroup}
            buttonText={t('models:chat.deleteGroup.cta')}
            title={t('models:chat.deleteGroup.cta')}
            message={t('models:chat.deleteGroup.message')}
          />
        )}
      </ChatInputProvider>
    </Box>
  );
});
