import { InternalRefetchQueriesInclude } from '@apollo/client';
import { Platform } from 'react-native';

import {
  Chat,
  ListMessagesDocument,
  ListTasksDocument,
  useDeleteTaskFilesMutation,
} from '@graphql/generated';

export const useDeleteTaskFiles = () => {
  const [deleteTaskFile] = useDeleteTaskFilesMutation();
  const deleteTaskFileById = (
    document: any,
    chatId?: Chat['id'],
    onComplete?: () => void
  ) => {
    const docids = document.map((doc) => doc.id);

    let refetchList:
      | InternalRefetchQueriesInclude
      | (() => InternalRefetchQueriesInclude)
      | undefined = ['listProjectDocuments', 'listTaskDocuments'];
    if (Platform.OS !== 'web') {
      refetchList = [
        'getProject',
        'listTasks',
        'listTaskDocuments',
        'listProjectDocuments',
        { query: ListTasksDocument },
      ];
      if (chatId) {
        refetchList.push({
          query: ListMessagesDocument,
          variables: { chatId: chatId },
        });
      }
    }

    deleteTaskFile({
      variables: { ids: docids },
      onCompleted: onComplete,
      refetchQueries: refetchList,
      update(cache, { data }) {
        if (!data || Platform.OS === 'web') return;
        document.forEach((doc) => {
          const cacheIdentifier = cache.identify(doc);
          cache.evict({ id: cacheIdentifier });
          cache.gc();
        });
      },
    });
  };

  return { deleteTaskFileById };
};
