import { BlobUpload } from '@rails/activestorage/src/blob_upload';
import * as Sentry from '@sentry/react-native';
import byteBuffer from 'bytebuffer';
import * as FileSystem from 'expo-file-system';
import { UploadProgressData } from 'expo-file-system/build/FileSystem.types';
import { Platform } from 'react-native';
import uuid from 'react-native-uuid';

import { Document, LocalFile, DirectUpload } from '@graphql/generated';
import { getFileMetadata } from '@utils/calculateChecksum';

export const getFileInfoPromises = async (
  documents: Document[]
): Promise<
  (FileSystem.FileInfo & Pick<Document, 'clientId' | 'contentType'>)[]
> => {
  return Promise.all(
    documents.map(async (document) => {
      const documentInfo = await Platform.select({
        web: async () => {
          const systemDocument = await localDocumentToFile(document);
          const { byte_size, checksum } = await getFileMetadata(systemDocument);
          return Promise.resolve({
            exists: true,
            isDirectory: false,
            modificationTime: new Date().getMilliseconds(),
            size: byte_size ?? 0,
            uri: document.file.url,
            md5: checksum,
          } as FileSystem.FileInfo);
        },
        default: async () =>
          await FileSystem.getInfoAsync(document.file.url, {
            md5: true,
            size: true,
          }),
      })();

      const { clientId, contentType } = document;

      return { ...documentInfo, clientId, contentType };
    })
  );
};

export const createDirectUploadAttributesFromFileInfo = (
  documentInfoForImages: (FileSystem.FileInfo &
    Pick<Document, 'clientId' | 'contentType'>)[]
) => {
  return documentInfoForImages.map((item) => {
    const { size, md5: documentInfoMd5, clientId, contentType } = item;
    const checksum =
      Platform.select({
        default: () =>
          documentInfoMd5 && byteBuffer.fromHex(documentInfoMd5).toBase64(),
        web: () => documentInfoMd5,
      })() || '';
    const filename = uuid.v4().toString();

    return {
      byteSize: size ?? 0,
      checksum,
      filename,
      clientId,
      contentType,
    };
  });
};

export const uploadFilesAsync = async (
  documentDirectUploads: DirectUpload[],
  documents: Document[],
  callback?: (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => void
) =>
  await Platform.select({
    default: uploadFilesAsyncNative,
    web: uploadFilesAsyncWeb,
  })(documentDirectUploads, documents, callback);

const uploadFilesAsyncNative = async (
  documentDirectUploads: DirectUpload[],
  documents: Document[],
  callback?: (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => void
): Promise<{ blobId: string; clientId: string }[]> => {
  return Promise.all(
    documentDirectUploads.map(async (directUpload, index) => {
      const { signedBlobId, clientId } = directUpload;

      const headers = JSON.parse(directUpload.headers) as Record<
        string,
        string
      >;
      console.log('==============upload task=============');
      const document = documents[index];
      const progressCallback = (progress: UploadProgressData) => {
        console.log({ progress });
        callback?.(document.clientId, progress);
      };
      const uploadTask = FileSystem.createUploadTask(
        directUpload.url,
        documents[index].file.url,
        {
          headers,
          httpMethod: 'PUT',
        },
        progressCallback
      );

      try {
        const response = await uploadTask.uploadAsync();

        if (response) {
          if (response.status === 200) {
            return { blobId: signedBlobId, clientId };
          } else {
            console.error(
              `Upload failed with status: ${response.status}, blobId: ${signedBlobId}, clientId: ${clientId}`
            );
            Sentry.captureMessage(
              `Upload failed with status: ${response.status}, blobId: ${signedBlobId}, clientId: ${clientId}`
            );
            throw new Error(
              `Upload failed with status: ${response.status}, blobId: ${signedBlobId}, clientId: ${clientId}`
            );
          }
        } else {
          console.error(
            `Upload failed without response. blobId: ${signedBlobId}, clientId: ${clientId}`
          );
          Sentry.captureMessage(
            `Upload failed without response. blobId: ${signedBlobId}, clientId: ${clientId}`
          );
          throw new Error(
            `Upload failed without response. blobId: ${signedBlobId}, clientId: ${clientId}`
          );
        }
      } catch (error) {
        console.error('Error during upload:', error);
        throw error;
      }
    })
  );
};

const uploadFilesAsyncWeb = async (
  documentDirectUploads: DirectUpload[],
  documents: Document[],
  _callback?: (
    documentClientId: string,
    uploadProgress: UploadProgressData
  ) => void
): Promise<{ blobId: string; clientId: string }[]> => {
  return Promise.all(
    documentDirectUploads.map(async (directUpload, index) => {
      const localDocument = documents[index];
      const document = await localDocumentToFile(localDocument);
      return await new Promise((resolve, reject) => {
        const rawHeaders = JSON.parse(directUpload.headers) as Record<
          string,
          string
        >;
        const headers = Object.keys(rawHeaders).reduce((acc, key) => {
          acc[key.toLowerCase()] = rawHeaders[key];
          return acc;
        }, {} as typeof rawHeaders);
        const blobUpload = new BlobUpload({
          file: document,
          directUploadData: { ...directUpload, headers },
        });

        blobUpload.create((error) => {
          if (error) {
            reject(error);
          }

          resolve({
            blobId: directUpload.signedBlobId,
            clientId: directUpload.clientId,
          });
        });
      });
    })
  );
};

/**
 * Converts a `Document` type object into a `File` object
 * @param localDocument The local document
 * @returns A `File`
 */
export const localDocumentToFile = async (localDocument: Document) => {
  const {
    file: { url },
    name,
  } = localDocument;
  const res: Response = await fetch(url);
  const blob: Blob = await res.blob();
  return new File([blob], name, { type: contentType(url) });
};

/**
 * Computes the document size of a base64 encoded document uri
 * @param documentUri A base64 encoded document uri
 * @returns The document size
 */
export const documentSize = (documentUri: string) => {
  const base64 = documentUri.includes(',')
    ? documentUri.slice(documentUri.lastIndexOf(','), 1)
    : documentUri;
  return base64.length - (base64.endsWith('==') ? 2 : 1);
};

/**
 * Retrieves a document type in mime-type format from a document uri string
 * @param documentUri The documentUri to parse
 * @returns The content type in mime-type format
 */
export const contentType = (documentUri: string) => {
  if (documentUri.startsWith('data:')) {
    return documentUri.slice(
      documentUri.indexOf('data:') + 'data:'.length,
      documentUri.lastIndexOf(';')
    );
  }
  return '';
};

/**
 * Formats a size into a human readable display
 * @param size Size value to format
 * @returns The formatted size
 */
export const formatSize = (size: number) => {
  if (!+size) return '';

  const byteSize = 1024;
  const KB = Math.pow(byteSize, 1);
  const MB = Math.pow(byteSize, 2);

  if (size < MB) {
    return `${(size / KB).toFixed(2)} KB`;
  }
  return `${(size / MB).toFixed(2)} MB`;
};

/**
 * Reads the target document into a uri
 * @param targetFile The document to read
 * @returns An object with the document uri and optional base64 encoding
 */
export const readFileToUri = (
  targetFile: Blob
): Promise<{ uri: string; base64?: string }> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => {
      reject(
        new Error(
          `Failed to read the selected media because the operation failed.`
        )
      );
    };
    reader.onload = ({ target }) => {
      const uri = target?.result;

      const returnRaw = () =>
        resolve({
          uri: uri as string,
        });

      if (typeof uri === 'string') {
        resolve({
          uri,
          // The blob's result cannot be directly decoded as Base64 without
          // first removing the Data-URL declaration preceding the
          // Base64-encoded data. To retrieve only the Base64 encoded string,
          // first remove data:*/*;base64, from the result.
          // https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
          base64: uri.slice(uri.indexOf(',') + 1),
        });
      } else {
        returnRaw();
      }
    };

    reader.readAsDataURL(targetFile);
  });
};

/**
 * Opens a file in a new tab.
 *
 * **NOTE**
 * Only available in the web/browser environment.
 * @param document The document to open
 */
export const openInNewTab = async (document: Document | LocalFile) => {
  if (Platform.OS !== 'web') return;

  const file = 'file' in document ? document.file : document;
  let link = '';

  // file will always contain a url, but if we have a cdnBaseUrl,
  // we have to use that to build the path instead.
  if (document.isImage && file.cdnBaseUrl) {
    link = file.cdnBaseUrl + file.path;
  } else {
    link = file.url;
  }

  if ('file' in document && link.startsWith('data:')) {
    const f = await localDocumentToFile(document);
    link = URL.createObjectURL(f);
  }

  link && window && 'open' in window && window.open(link, '_blank');
};

const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

const getDurationFromDataUrl = async (url: string) => {
  const context = new AudioContext();

  const buffer = base64ToArrayBuffer(url.split(',')[1]);

  const decoded = await context.decodeAudioData(buffer).catch(() => {
    return new AudioBuffer({ length: 0, numberOfChannels: 0, sampleRate: 0 });
  });

  return Math.round(decoded.duration);
};

/**
 * Converts a `File` type to a `LocalFile` object
 * @param file File to convert
 * @returns A LocalFile object
 */
export const fileToLocalFile = async (file: File, getAudioDuration = false) => {
  const { uri: url } = await readFileToUri(file);
  const clientId = uuid.v4().toString();
  const isAudio = file.type.startsWith('audio');
  const duration =
    getAudioDuration && isAudio ? await getDurationFromDataUrl(url) : undefined;
  return {
    isImage: file.type.startsWith('image') || file.type.startsWith('video'),
    contentType: file.type,
    isPreviewable:
      file.type.startsWith('image') || file.type.startsWith('video'),
    thumbnail: '',
    name: file.name,
    url,
    size: file.size,
    clientId,
    id: clientId,
    cdnBaseUrl: '',
    path: '',
    isAudio,
    duration,
    __typename: 'LocalFile',
  } as LocalFile;
};
