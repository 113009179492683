import dayjs from 'dayjs';
import * as Clipboard from 'expo-clipboard';
import React, { ComponentProps, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Toast from 'react-native-toast-notifications';

import { useWebDrawer } from '@components/Web/Drawer/WebDrawerContext';
import { PopupIcon } from '@components/Web/PopupIcon';
import {
  Project,
  useArchiveProjectMutation,
  useCreateInviteMutation,
  useLeaveProjectMutation,
  useUnarchiveProjectMutation,
} from '@graphql/generated';
import useMe from '@hooks/useMe';
import { useProjectMembership } from '@hooks/useProjectMembership';
import { useWebDrawerNavigator } from '@hooks/useWebDrawerNavigator';
import { useAppNavigation } from '@navigation/useAppNavigation';
import theme from '@themes/theme';

import { ConfirmModal } from './ConfirmModal';

type Props = Pick<ComponentProps<typeof PopupIcon>, 'backgroundColor'> & {
  project: Project;
  popPosition?: string;
  popSize?: 'big' | 'small';
};

export const ProjectDetailModal = (props: Props) => {
  const navigation = useAppNavigation();
  const { setShowProjectColorAndIconModal } = useWebDrawerNavigator();
  const { setIsCreateProjectOpen, setEditProjectId } = useWebDrawer();
  const [showLeaveProjectModal, setShowLeaveProjectModal] = useState(false);
  const toastRef = useRef();
  const { t } = useTranslation('models');

  const { project, popPosition, popSize } = props;

  const { members } = project || { members: [] };
  const { isAdminOrHigher, isEditorOrHigher } = useProjectMembership(project);
  const { me } = useMe();

  const isOwner = me?.id === project?.creator.id;
  const isTeamOwnerOrAdmin =
    project?.currentUserTeamRole === 'OWNER' ||
    project?.currentUserTeamRole === 'ADMIN';
  const isTeamMember = project?.currentUserTeamRole === 'MEMBER';

  const [leaveProject] = useLeaveProjectMutation({
    onCompleted: () =>
      navigation.navigate('projects-stack', { screen: 'allprojects' }),
    refetchQueries: ['listProjects'],
  });

  const [archiveProject] = useArchiveProjectMutation({
    onCompleted: () => navigation.goBack(),
    refetchQueries: ['listProjects'],
  });

  const [unarchiveProject] = useUnarchiveProjectMutation({
    onCompleted: () => navigation.goBack(),
    refetchQueries: ['listProjects'],
  });

  const [createInviteMutation] = useCreateInviteMutation({
    onCompleted: ({ createInvite }) => {
      if (createInvite) {
        const formattedLink = createInvite.replace(
          'register/register-with-email',
          'invited-user'
        );
        copyToClipboard(formattedLink);
      }
    },
  });

  const copyToClipboard = async (link: string) => {
    if (!link) return; // Early return if link is falsy
    await Clipboard.setStringAsync(link);

    toastRef.current?.show('Copied', {
      duration: 2000,
    });
  };

  const handleLeaveProject = useCallback(() => {
    leaveProject({ variables: { id: project.id || '' } });
  }, [leaveProject, project.id]);

  const menuList: ComponentProps<typeof PopupIcon>['popupProps']['menuList'] = [
    ...(!project.archivedAt
      ? [{ iconName: 'Pin', name: 'Pin', isComingSoon: true }]
      : []),

    ...((isAdminOrHigher || isTeamOwnerOrAdmin || isTeamMember) &&
    !project.archivedAt
      ? [
          {
            iconName: 'Edit',
            name: t('projects.edit.title'),
            onSelect: () => {
              setEditProjectId(project.id);
              setIsCreateProjectOpen(true);
            },
          },
        ]
      : []),
    ...(isAdminOrHigher && !project.archivedAt
      ? [
          {
            iconName: 'Image',
            name: t('projects.edit.setColorAndIcon'),
            onSelect: () => {
              setShowProjectColorAndIconModal(true);
              navigation.navigate('projects-stack', {
                screen: 'colorSelect',
                params: { projectId: project.id },
              });
            },
          },
        ]
      : []),
    ...(isEditorOrHigher || isTeamOwnerOrAdmin || isTeamMember
      ? [
          {
            iconName: 'Duplicate',
            disable: !(isTeamMember || isTeamOwnerOrAdmin),
            name: 'Duplicate',
            onSelect: () => {
              setShowProjectColorAndIconModal(true);
              navigation.navigate('projects-stack', {
                screen: 'duplicate',
                params: { projectId: project.id },
              });
            },
          },
        ]
      : []),
    ...((isEditorOrHigher || isTeamOwnerOrAdmin) &&
    !project.archivedAt &&
    members?.length
      ? [{ iconName: 'Chat', name: 'Announcement', isComingSoon: true }]
      : []),

    ...(!project.archivedAt
      ? [
          {
            iconName: 'Share',
            name: 'Share',
            onSelect: () =>
              createInviteMutation({ variables: { projectId: project.id } }),
          },
        ]
      : []),

    ...((!project.archivedAt && !isTeamOwnerOrAdmin) ||
    (isTeamOwnerOrAdmin && isTeamMember && !isOwner)
      ? [
          {
            iconName: 'LogOut',
            name: 'Leave',
            onSelect: () => setShowLeaveProjectModal(true),
          },
        ]
      : []),
    ...((isAdminOrHigher || !isTeamMember) &&
    -dayjs().diff(project.team?.plan?.expiration, 'day') > 0
      ? [
          {
            iconName: 'Archive',
            name: project.archivedAt ? 'Restore' : 'Archive',
            onSelect: () =>
              project.archivedAt
                ? unarchiveProject({ variables: { id: project.id || '' } })
                : archiveProject({ variables: { id: project.id || '' } }),
          },
        ]
      : []),

    ...((isAdminOrHigher || isTeamOwnerOrAdmin) && !project.archivedAt
      ? [
          {
            iconName: 'Trash2',
            name: 'Delete',
            isRed: true,
            isComingSoon: true,
          },
        ]
      : []),
  ];

  return (
    <>
      <PopupIcon
        enableHoverEffect={true}
        {...props}
        name='MoreVertical'
        variant={popSize === 'big' ? 'l' : 's'}
        paddingHorizontal={popSize === 'big' ? 'xxs' : 'xs'}
        paddingVertical={popSize === 'big' ? 'xxs' : 'm'}
        color='textPrimary'
        popupProps={{
          menuList,
          position:
            popPosition === 'top' ? ['left', 'top'] : ['left', 'bottom'],
          offset: [0, theme.spacing.m],
        }}
      />
      {showLeaveProjectModal && (
        <ConfirmModal
          showModal={showLeaveProjectModal}
          onClose={() => setShowLeaveProjectModal(false)}
          onPress={handleLeaveProject}
          buttonText={t('models:leaveProject.title')}
          title={t('models:leaveProject.title')}
          message={t('models:leaveProject.message')}
        />
      )}
      <Toast ref={toastRef} />
    </>
  );
};
