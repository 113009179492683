import React, { createRef } from 'react';
import {
  FlatList,
  Platform,
  TouchableOpacity,
  useWindowDimensions,
  Image,
} from 'react-native';

import CachedImage from '@components/CachedImage/CachedImage';
import PlayCircle from '@components/Icons/PlayCircle';
import { Box } from '@components/Restyle';
import ActivityIndicatorLoading from '@components/shared/ActivityIndicatorLoading';
import Icon from '@components/shared/Icon/Icon';
import { Document, LocalFile } from '@graphql/generated';
import theme from '@themes/theme';

interface ChatImagesProps {
  list: (Document | LocalFile)[];
  onPress?: (image: Document | LocalFile) => void;
  onLongPress?: () => void;
  onDelete?: (image: LocalFile) => void;
  isForwarded?: boolean;
}

const ChatImages: React.FC<ChatImagesProps> = ({
  list,
  onPress,
  onLongPress,
  onDelete,
  isForwarded,
}) => {
  const _horizontalScrollRef = createRef<FlatList>();
  const { width = 360 } =
    Platform.select({
      native: useWindowDimensions(),
      web: { width: 306, fontScale: 1, height: 1, scale: 1 },
    }) ?? {};
  const isChatInput = !!onDelete;
  const CHAT_INPUT_PADDING = 40;
  const CHAT_MESSAGE_PADDING = 94;
  const outerPadding = isChatInput ? CHAT_INPUT_PADDING : CHAT_MESSAGE_PADDING;
  const largeWidth = width - outerPadding - (isForwarded ? 16 : 0); // FROM FIGMA
  const singleImage = list.length === 1;
  const imageRowLimit = isChatInput ? 5 : 4;
  const imageRowSpaces = imageRowLimit - 1;
  const maxBoxWidth = '100%';
  const multiImageWidth =
    (largeWidth - theme.spacing.xs * imageRowSpaces) / imageRowLimit;
  /* eslint-disable no-nested-ternary */
  const size = singleImage && !isChatInput ? largeWidth : multiImageWidth;
  /* eslint-enable no-nested-ternary */

  const getMarginRight = (i: number): number => {
    if ((i + 1) % imageRowLimit || Platform.OS === 'web') {
      return (largeWidth - multiImageWidth * imageRowLimit) / imageRowSpaces;
    } else {
      return 0;
    }
  };

  const handleContentChange = () => {
    if (list.length <= imageRowLimit) {
      // scroll to beginning since we are disabling scroll
      _horizontalScrollRef.current?.scrollToIndex({
        index: 0,
        animated: true,
      });
    }
  };

  const isDocument = (item: Document | LocalFile): item is Document => {
    return item.__typename === 'Document';
  };

  // TODO: Reduce this type down to Document, LocalFile is no longer used
  // Render image with thumbnail for videos and default behavior for images
  const renderImage = (item: Document | LocalFile, i: number) => {
    // TODO: Remove this note, it's inaccurate
    // NOTE: Image begins as a LocalFile instance that matches the shape of File type.
    // After processing the image attachment, a Document instance is returned and Document
    // type has a `file` field which is of type File.
    const isVideo = item.isVideo || item?.contentType?.includes('video');
    const image = isDocument(item) ? item.file : item;
    const isLocal =
      image.url.startsWith('file:') || image.url.startsWith('data:');
    const thumbnailUri = image.thumbnail;

    return (
      <TouchableOpacity
        key={image.id}
        disabled={isChatInput}
        onPress={() => {
          onPress && onPress(item);
        }}
        onLongPress={() => onLongPress && onLongPress()}
        style={[
          {
            //changed the size fixes to fit 4 images in a line in web
            width: Platform.OS === 'web' ? size - 2 : size,
            height: Platform.OS === 'web' ? size - 2 : size,
            flexDirection: 'row',
            marginRight: isChatInput ? theme.spacing.xs : getMarginRight(i),
            marginBottom: theme.spacing.xs,
          },
        ]}>
        <Box
          accessibilityLabel='Message'
          justifyContent='center'
          alignItems='center'
          pointerEvents='auto'>
          {isVideo && thumbnailUri ? (
            <Image
              source={{ uri: thumbnailUri ? thumbnailUri : image.cdnBaseUrl }}
              style={{
                width: size,
                height: size,
                borderRadius: theme.spacing.xs,
              }}
              resizeMode='cover'
            />
          ) : (
            <CachedImage
              image={image}
              width={size}
              height={size}
              from='search'
              isRemoveable={!!onDelete}
              borderRadius={theme.spacing.xs}
              contentType={item.contentType}
            />
          )}

          {isVideo && <PlayCircle />}

          {!isChatInput && isLocal && (
            <ActivityIndicatorLoading imageWidth={size} />
          )}

          {isChatInput && (
            <TouchableOpacity
              onPress={() => onDelete(image as LocalFile)}
              style={{
                position: 'absolute',
                top: 0,
                right: theme.spacing.xxxs,
              }}
              hitSlop={{ top: 10, bottom: 10, right: 10, left: 10 }}>
              <Icon
                name='X2'
                variant='l'
                marginTop='xxxs'
                color='textPrimary'
              />
            </TouchableOpacity>
          )}
        </Box>
      </TouchableOpacity>
    );
  };

  return (
    <Box
      flexDirection='row'
      flexWrap={isChatInput && Platform.OS !== 'web' ? 'nowrap' : 'wrap'}
      paddingTop='xs'
      paddingRight='none'
      paddingHorizontal={!onDelete || Platform.OS === 'web' ? 'xs' : undefined}
      borderRadius='xs'
      justifyContent='flex-start'
      borderWidth={!isChatInput || Platform.OS === 'web' ? 1 : 0}
      width={maxBoxWidth}
      borderColor='grey02'>
      {!isChatInput || Platform.OS === 'web' ? (
        list.map((item: Document | LocalFile, i) => renderImage(item, i))
      ) : (
        <FlatList
          ref={_horizontalScrollRef}
          keyboardShouldPersistTaps='always'
          data={Array.isArray(list) ? list : []}
          horizontal
          scrollEnabled={list.length > imageRowLimit}
          keyExtractor={(item) => item.id}
          renderItem={({ item, index }) => renderImage(item, index)}
          ListFooterComponent={() => <Box marginRight='xs' />}
          ListHeaderComponent={() => <Box marginRight='m' />}
          onContentSizeChange={handleContentChange}
        />
      )}
    </Box>
  );
};

export default ChatImages;
